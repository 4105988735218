const { initializeApp } = require("firebase/app");
const { getMessaging, getToken } = require("firebase/messaging");

const firebaseConfig = {
  apiKey: "AIzaSyBAjCiMVT_ZvYcSwjSDue4t5A5Zbekt8oE",
  authDomain: "fcm-reactjs-b88a5.firebaseapp.com",
  projectId: "fcm-reactjs-b88a5",
  storageBucket: "fcm-reactjs-b88a5.appspot.com",
  messagingSenderId: "921912862160",
  appId: "1:921912862160:web:adcf2e56fdaf4aa91945d5",
  measurementId: "G-LT5RW2EKBB",
};

const validkey =
  "BGoC32i6cqi_yZdVs6jtMEShAACmMETxqEQhK9B-Gohc2655MGlSGY745w_OQ_0RqA409LsNtr4c-75bZ73nJk0";

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestFcmToken = async () => {
  return Notification.requestPermission()
    .then((permission) => {
      if (permission === "granted") {
        
        return getToken(messaging, { validkey });
      } else {
        throw new Error("Notification permission not granted");
      }
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
};
