import { useNavigate } from "react-router-dom";
import { api } from "../Environments/Environment.Test.js";
import { FaA } from "react-icons/fa6";

const authUrl =`${api}/restaurant`;

export function OnBoardRes(requestBody){
  // const navigate = useNavigate();

    return new Promise(async (resolve) => {
        try {
          const response = await fetch(`${authUrl}/onboardrestaurant`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
          });
          const data = await response.json();
          resolve({ data });
        } catch (error) {
          resolve({ error: error.message });
        }
      });
   

  }

  export function LogInAdminRes(requestBody){
    // const navigate = useNavigate();
  
      return new Promise(async (resolve) => {
          try {
            const response = await fetch(`${authUrl}/dashboard/admin/login`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestBody),
            });
            const data = await response.json();
            resolve({ data });
          } catch (error) {
            resolve({ error: error.message });
          }
        });
     
  
    }