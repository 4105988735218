import React, { createContext, useEffect } from 'react';
import './App.css';
import TableOverview from './Component/TableOverview';
import Header from './Component/Header/Header';
import Reports from './Component/Report/Report';
import AcceptOrder from './Component/AcceptOrder/AcceptOrder.main';
import OrderListLifeTime from './Component/Report/OrdersListLifetime';
import OrderMain from './Component/Orders/OrderMain';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AuthGuard from './Component/AuthGard/AuthGuard';
import Login from './Component/AuthGard/LogIn';
import ItemManagement from './Component/Menu/MenuManagament';
import Setting from './Component/Setting/Setting';
import SignUp from './Component/AuthGard/SignUp';
import { ParcelMain } from './Component/Parcel/Parcel.main';





function App() {
  // if ('serviceWorker' in navigator) {
  //   navigator.serviceWorker.addEventListener('message', function(event) {
  //     alert("Shubh")
  //     window.location.reload();
  //     console.log('Received message from service worker:', event.data.notification.title);
  //     if (event.data.notification.title=="NOTIFICATION_RECEIVED") {
  //     // location.reload();
       
  //     }
  //   });
  // }

  

  
  
  
  return (
    <Router>
      <div className="flex min-h-screen">
        {/* Sidebar - Header component */}
        <div className=" bg-white shadow-lg h-screen fixed">
          <Header />
        </div>

        {/* Main content */}
        <main className="ml-64 flex-1 bg-gray-100 overflow-y-auto">
          <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            <div className="px-4 py-6 sm:px-0">
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />

                <Route
                  path="/table-overview"
                  element={
                    <AuthGuard>
                      <TableOverview />
                    </AuthGuard>
                  }
                />
                <Route
                  path="/reports"
                  element={
                    <AuthGuard>
                      <Reports />
                    </AuthGuard>
                  }
                />
                <Route
                  path="/accept-order"
                  element={
                    <AuthGuard>
                      <AcceptOrder />
                    </AuthGuard>
                  }
                />
                <Route
                  path="/menu-managment"
                  element={
                    <AuthGuard>
                      <ItemManagement />
                    </AuthGuard>
                  }
                />
                <Route
                  path="/setting"
                  element={
                    <AuthGuard>
                      <Setting />
                    </AuthGuard>
                  }
                />
                <Route
                  path="/order-list-lifetime"
                  element={
                    <AuthGuard>
                      <OrderListLifeTime />
                    </AuthGuard>
                  }
                />
                <Route
                  path="/order-main"
                  element={
                    <AuthGuard>
                      <OrderMain />
                    </AuthGuard>
                  }
                />
                <Route
                  path="/order-parcel"
                  element={
                    <AuthGuard>
                      <ParcelMain />
                    </AuthGuard>
                  }
                />
                <Route path="*" element={<Navigate to="/table-overview" />} /> {/* Redirect to table overview if route is unknown */}
              </Routes>
            </div>
          </div>
        </main>
      </div>
    </Router>
  );
}

export default App;
