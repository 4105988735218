import React from 'react';

const filters = ['All', 'Open', 'In Progress', 'Ready', 'Served', 'Cancelled'];

const OrderFilters = ({ activeFilter, setActiveFilter }) => {
  return (
    // <div className="flex justify-around my-4">
    //   {filters.map((filter) => (
    //     <button
    //       key={filter}
    //       onClick={() => setActiveFilter(filter)}
    //       className={`p-2 rounded ${
    //         activeFilter === filter ? 'bg-gray-500 text-white' : 'bg-gray-200'
    //       }`}
    //     >
    //       {filter}
    //     </button>
    //   ))}
    // </div>
    <>
    </>
  );
};

export default OrderFilters;
