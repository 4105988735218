import React, { useEffect, useState } from 'react';
import logo from "../../img/logo.png";
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Header = () => {
  const [token, setToken] = useState();
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await new Promise((resolve) => {
          setTimeout(() => {
            resolve(localStorage.getItem("authToken"));
          }, 500);
        });
        setToken(token);
      } catch (error) {
        console.error('Failed to fetch token', error);
      }
    };

    fetchToken();
  }, []);

  return (
    <aside className={`bg-white shadow-lg ${isCollapsed ? 'w-16' : 'w-64'} min-h-screen transition-all duration-300`}>
      <div className="flex flex-col items-center py-6">
        <button
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="mb-4 focus:outline-none"
        >
          <i className={`fas fa-${isCollapsed ? 'bars' : 'times'} text-xl`}></i>
        </button>
        {!isCollapsed && (
          <>
            <img src={logo} alt="Logo" className="h-10 mb-4" />
            <h1 className="text-2xl font-bold text-green-600">QRAPID</h1>
          </>
        )}
      </div>
      <nav className="flex flex-col space-y-4 mt-8"  onClick={()=>{
            window.location.reload();
          }}>
        <Link to="/table-overview" className="flex items-center text-gray-900 hover:text-green-600 px-4 py-2">
          <i className="fas fa-table mr-3"></i>
          {!isCollapsed && <span>Table</span>}
        </Link>
        <Link to="/reports" className="flex items-center text-gray-900 hover:text-green-600 px-4 py-2">
          <i className="fas fa-chart-line mr-3"></i>
          {!isCollapsed && <span>Dashboard</span>}
        </Link>
        <Link to="/menu-managment" className="flex items-center text-gray-900 hover:text-green-600 px-4 py-2">
          <i className="fas fa-utensils mr-3"></i>
          {!isCollapsed && <span>Menu</span>}
        </Link>
        <Link to="/order-main" className="flex items-center text-gray-900 hover:text-green-600 px-4 py-2">
          <i className="fas fa-receipt mr-3"></i>
          {!isCollapsed && <span>Orders</span>}
        </Link>
        <Link to="/order-list-lifetime" className="flex items-center text-gray-900 hover:text-green-600 px-4 py-2">
          <i className="fas fa-file-alt mr-3"></i>
          {!isCollapsed && <span>Reports</span>}
        </Link>
        <Link to="/setting" className="flex items-center text-gray-900 hover:text-green-600 px-4 py-2">
          <i className="fas fa-cog mr-3"></i>
          {!isCollapsed && <span>Settings</span>}
        </Link>
      </nav>
      <div className="flex items-center justify-center mt-8">
        {token && (
          <button
            onClick={() => {
              localStorage.removeItem("authToken");
              window.location.reload();
            }}
            className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
          >
            Logout
          </button>
        )}
      </div>
    </aside>
  );
};

export default Header;
