import { api } from "../Environments/Environment.Test";

const authUrl =`${api}/restaurant`;

export function TableData(token){
  // const navigate = useNavigate();


    return new Promise(async (resolve) => {
        try {
          const response = await fetch(`${authUrl}/get/room/table`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              "Authorization":`Bearer ${token}`
            },
            // body: JSON.stringify(requestBody),
          });
          const data = await response.json();
          resolve({ data });
        } catch (error) {
          resolve({ error: error.message });
        }
      });
   

  }

  

  export function GetTableData(token){
    // const navigate = useNavigate();
  
  
      return new Promise(async (resolve) => {
          try {
            const response = await fetch(`${authUrl}/admin/printedKOT`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                "Authorization":`Bearer ${token}`
              },
              // body: JSON.stringify(requestBody),
            });
            const data = await response.json();
            resolve({ data });
          } catch (error) {
            resolve({ error: error.message });
          }
        });
     
  
    }
    

    export function RequestedKOTData(token){
      // const navigate = useNavigate();
    
    
        return new Promise(async (resolve) => {
            try {
              const response = await fetch(`${authUrl}/admin/RequestKOT`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  "Authorization":`Bearer ${token}`
                },
                // body: JSON.stringify(requestBody),
              });
              const data = await response.json();
              resolve({ data });
            } catch (error) {
              resolve({ error: error.message });
            }
          });
       
    
      }