import React, { useState } from "react";
import CartItem from "./CartItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import OldCartItem from "./OldItem";
import CancleKOTDoted from "./CancleKOTDoted";
import ExtraItems from "./ExtraItemShow";

const Cart = ({ cartItems, onQuantityChange, onDeleteItem, onCloseCart ,bookedOrder,billNo,filterItem,extraCartItems}) => {
  console.log(cartItems,"....cart Item");
  const [cancleItem , setCancleItem ] = useState([]);
  const handleKotCancle = (itemid,item)=>{
    console.log(billNo,itemid,"....../item/,,,");
    setCancleItem((prv)=>[...prv,item])
    filterItem(itemid,item)


    

  }
  
  return (
    <div className="bg-white p-6 border-l border-gray-300">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-bold">Selected Items</h3>
        <button className="text-2xl" onClick={onCloseCart}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <ul>
      {
        cancleItem && cancleItem.map((item, index)=>(
          <CancleKOTDoted 
          key={index}
          item={item}
         
          />
        ))

      }
      </ul>

      <ul>
      {
        bookedOrder && bookedOrder.map((item, index)=>(
          <OldCartItem 
          key={index}
          item={item}
          handleKotCancle={handleKotCancle}
          billNo={billNo}
          />
        ))

      }
      </ul>
      <ul>
      
        {extraCartItems.map((item, index) => (
          <ExtraItems
            key={index}
            item={item}
            onQuantityChange={onQuantityChange}
            onDeleteItem={onDeleteItem}
          />
        ))}
      </ul>

      <ul>
        {cartItems.map((item, index) => (
          <CartItem
            key={index}
            item={item}
            onQuantityChange={onQuantityChange}
            onDeleteItem={onDeleteItem}
          />
        ))}
      </ul>
    </div>
  );
};

export default Cart;
