import React, { useState } from 'react';

const tabs = ['Table Service']; //, 'Parcel'

const MenuTabs = ({ activeTab, setActiveTab }) => {
  return (
    <div className="flex justify-around bg-gray-200 p-2 rounded">
      {tabs.map((tab) => (
        <button
          key={tab}
          onClick={() => setActiveTab(tab)}
          className={`p-2 w-full text-center rounded ${
            activeTab === tab ? 'bg-gray-500 text-white' : 'bg-gray-300'
          }`}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

export default MenuTabs;
