import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CSVLink } from 'react-csv';
import { reportallYear } from './Services/ReportServices';

const OrderListLifeTime = () => {
  const [startDate, setStartDate] = useState(new Date('2024-08-08'));
  const [endDate, setEndDate] = useState(new Date('2024-08-15'));
  const [filteredData, setFilteredData] = useState([]);
  const [orders, setOrders] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const handleFilter = () => {
    const filtered = orders.filter(
      (order) =>
        new Date(order.orderDate) >= startDate && new Date(order.orderDate) <= endDate
    );
    setFilteredData(filtered);
    calculateTotalAmount(filtered);
  };

  const calculateTotalAmount = (data) => {
    const total = data.filter((data)=>data.paymentmethod!="order cancle").reduce((acc, order) => acc + order.amount, 0);
    setTotalAmount(total);
  };

  const filterByMethod = (method) => {
    const filtered = orders.filter(order => order.paymentmethod === method);
    setFilteredData(filtered);
    calculateTotalAmount(filtered);
  };

  const filterLastNDays = (days) => {
    const today = new Date();
    const previousDate = new Date();
    previousDate.setDate(today.getDate() - days);
    setStartDate(previousDate);
    setEndDate(today);
    const filtered = orders.filter(
      (order) => new Date(order.orderDate) >= previousDate && new Date(order.orderDate) <= today
    );
    setFilteredData(filtered);
    calculateTotalAmount(filtered);
  };

  const filterToday = () => {
    const today = new Date();
    setStartDate(today);
    setEndDate(today);
    const filtered = orders.filter(
      (order) => new Date(order.orderDate).toDateString() === today.toDateString()
    );
    setFilteredData(filtered);
    calculateTotalAmount(filtered);
  };

  const resetFilters = () => {
    setStartDate(new Date('2024-08-08'));
    setEndDate(new Date('2024-08-15'));
    setFilteredData(orders);
    calculateTotalAmount(orders);
  };

  useEffect(() => {
    const fetch = async () => {
      const list = await reportallYear();
      setOrders(list.data.data);
      setFilteredData(list.data.data); // Set initially
      calculateTotalAmount(list.data.data); // Calculate total for all data
    };
    fetch();
  }, []);

  // Prepare CSV data with the total amount row added at the end
  const csvDataWithTotal = [...(filteredData.length > 0 ? filteredData : orders), { invoiceNo: '', customerName: '', waiter: '', table: '', state: '', orderDate: '', amount: `Total: RS ${totalAmount}`, paymentmethod: '' }];

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl font-bold mb-6">Order List</h2>

      <div className="flex items-center space-x-4 mb-4">
        <div>
          <label className="block font-semibold">From:</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className="p-2 border rounded"
          />
        </div>
        <div>
          <label className="block font-semibold">To:</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            className="p-2 border rounded"
          />
        </div>
        <button
          onClick={handleFilter}
          className="bg-green-500 text-white px-4 py-2 rounded"
        >
          Search
        </button>
        <button
          onClick={resetFilters}
          className="bg-orange-500 text-white px-4 py-2 rounded"
        >
          Reset
        </button>
      </div>

      {/* Payment Method Filter Buttons */}
      <div className="flex space-x-2 mb-4">
        <button onClick={() => filterByMethod('cash')} className="bg-blue-500 text-white px-4 py-2 rounded">
          Cash
        </button>
        <button onClick={() => filterByMethod('online')} className="bg-yellow-500 text-white px-4 py-2 rounded">
          Online
        </button>
        <button onClick={() => filterByMethod('card')} className="bg-green-500 text-white px-4 py-2 rounded">
          Card
        </button>
        <button onClick={() => filterByMethod('partial')} className="bg-red-500 text-white px-4 py-2 rounded">
          Partial
        </button>
      </div>

      {/* Previous Days Filter Buttons */}
      <div className="flex space-x-2 mb-4">
        <button onClick={filterToday} className="bg-pink-500 text-white px-4 py-2 rounded">
          Today
        </button>
        <button onClick={() => filterLastNDays(7)} className="bg-purple-500 text-white px-4 py-2 rounded">
          Last 7 Days
        </button>
        <button onClick={() => filterLastNDays(30)} className="bg-teal-500 text-white px-4 py-2 rounded">
          Last 30 Days
        </button>
      </div>

      {/* Total Amount Display */}
      <div className="mb-4 font-semibold text-lg">
        Total Amount: RS {totalAmount}
      </div>

      {/* CSV Download */}
      <div className="flex space-x-2 mb-4">
        <CSVLink
          data={csvDataWithTotal}
          headers={[
            { label: 'SI', key: 'id' },
            { label: 'Invoice No', key: 'invoiceNo' },
            { label: 'Customer Name', key: 'customerName' },
            { label: 'Waiter', key: 'waiter' },
            { label: 'Table', key: 'table' },
            { label: 'State', key: 'state' },
            { label: 'Order Date', key: 'orderDate' },
            { label: 'Amount', key: 'amount' },
            { label: 'Method', key: 'paymentmethod' }
          ]}
          filename={'orders_with_total_amount.csv'}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Download File
        </CSVLink>
      </div>

      {/* Order Table */}
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2">Invoice No</th>
            <th className="py-2">Customer Name</th>
            <th className="py-2">Waiter</th>
            <th className="py-2">Table</th>
            <th className="py-2">State</th>
            <th className="py-2">Order Date</th>
            <th className="py-2">Amount</th>
            <th className="py-2">Method</th>
          </tr>
        </thead>
        <tbody>
          {(filteredData.length > 0 ? filteredData : orders)
            .sort((a, b) => b.invoiceNo - a.invoiceNo) // Sort by invoiceNo (big to small)
            ?.map((order, index) => (
              <tr key={index}>
                <td className="border px-4 py-2">{order.invoiceNo}</td>
                <td className="border px-4 py-2">{order.customerName}</td>
                <td className="border px-4 py-2">{order.waiter}</td>
                <td className="border px-4 py-2">{order.table}</td>
                <td className="border px-4 py-2">{order.state}</td>
                <td className="border px-4 py-2">{order.orderDate}</td>
                <td className="border px-4 py-2">
                  <div>{order.amount}</div>
                  {order.paymentmethod === 'partial' &&
                    order.partialAmoutsplit?.map((ele, idx) => (
                      <p key={idx}>
                        {ele.name}: {ele.amount}
                      </p>
                    ))}
                </td>
                <td className="border px-4 py-2">{order.paymentmethod}</td>
              </tr>
            ))}
          <tr>
            <td colSpan="6" className="text-right font-bold">Total Amount</td>
            <td colSpan="2">RS {totalAmount}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OrderListLifeTime;
