import React from 'react';

const SearchBar = ({ onSearch }) => {
  return (
    <input
      type="number"
      placeholder="Search for orders bill No"
      className="p-2 w-full border rounded"
      onChange={(e) => onSearch(e.target.value)}
    />
  );
};

export default SearchBar;
