import React, { useState } from 'react';

const SendOfferNotification = () => {
  const [notification, setNotification] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleNotificationChange = (e) => {
    setNotification(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleSendNotification = () => {
    // Logic to send a notification via WhatsApp
    const message = `Notification: ${notification}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="mb-4">
      <h2 className="text-lg font-bold">Send Offer Notification on WhatsApp</h2>
      <textarea
        value={notification}
        onChange={handleNotificationChange}
        placeholder="Enter Notification Message"
        className="border p-2 w-full mb-2"
      />
      <input
        type="tel"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        placeholder="Enter Customer Phone Number"
        className="border p-2 w-full mb-2"
      />
      <button
        onClick={handleSendNotification}
        className="bg-blue-500 text-white px-4 py-2 rounded"
      >
        Send Notification via WhatsApp
      </button>
    </div>
  );
};

export default SendOfferNotification;
