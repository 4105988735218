import { api } from "../../../Environments/Environment.Test";


const authUrl = `${api}/restaurant`;
export function billGenerate(requestBody) {
    // const navigate = useNavigate();
  
    return new Promise(async (resolve) => {
      try {
        const token = await localStorage.getItem("authToken");
        const response = await fetch(`${authUrl}/waiter/otherdata/generatebill`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        resolve({ data });
      } catch (error) {
        resolve({ error: error.message });
      }
    });
  }

//   /waiter/otherdata/get/generatebill


  export function getbillGenerate(requestBody) {
    // const navigate = useNavigate();
  
    return new Promise(async (resolve) => {
      try {
        const token = await localStorage.getItem("authToken");
        const response = await fetch(`${authUrl}/waiter/otherdata/get/generatebill`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        resolve({ data });
      } catch (error) {
        resolve({ error: error.message });
      }
    });
  }