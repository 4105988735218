// src/components/OrderList.js
import React from 'react';

const OrderList = ({ title, orders }) => {
  return (
    <div className="bg-white shadow rounded-lg p-4">
      <h2 className="text-lg font-semibold text-gray-700">{title}</h2>
      <div className="mt-4 space-y-4 max-h-48 overflow-y-auto">
        {orders.slice(0, 3).map((order, index) => (
          <div key={index} className="border-b pb-2">
            <p className="font-semibold text-gray-800">Name: {order.name}</p>
            <p className="text-gray-600">Phone: {order.phone}</p>
            <p className="text-green-500">
              Order No.: {order.orderNo}
            </p>
            <p className="text-gray-600">Table No: {order.tableNo}</p>
            <p className="text-gray-600">Time: {order.time}</p>
          </div>
        ))}
        {orders.length > 3 && (
          <div className="mt-4 space-y-4 max-h-48 overflow-y-auto">
            {orders.slice(3).map((order, index) => (
              <div key={index} className="border-b pb-2">
                <p className="font-semibold text-gray-800">Name: {order.name}</p>
                <p className="text-gray-600">Phone: {order.phone}</p>
                <p className="text-green-500">
                  Order No.: {order.orderNo}
                </p>
                <p className="text-gray-600">Table No: {order.tableNo}</p>
                <p className="text-gray-600">Time: {order.time}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderList;
