// src/components/Header.js
import React from 'react';

const Header1 = () => {
  return (
    // <header className="bg-white shadow flex justify-between items-center py-4 px-6">
    //   <div className="flex items-center">
    //     {/* <img src="/path-to-your-logo.png" alt="QRAPID" className="h-8 w-auto" /> */}
    //     <h1 className="ml-2 text-2xl font-bold text-green-600">QRAPID - Table Overview</h1>
    //   </div>
    //   <div className="flex items-center space-x-4">
    //     <button className="px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-900">
    //       + Request New Table
    //     </button>
        
    //   </div>
    // </header>
    <></>
  );
};

export default Header1;
