export  const PrintBills = (greenItems,subTotal,discount,hotelData,roomData,billNo) => {
    // Open a new window for printing
    console.log(greenItems);
    
    const WinPrint = window.open('', '', 'width=auto,height=auto');
    const date = new Date();
const day = date.getDate(); // Day of the month (1-31)
const month = date.getMonth() + 1; // Month (0-11, so we add 1 to get 1-12)
const year = date.getFullYear();
const time = date.toLocaleTimeString()
console.log(greenItems);

// const data= 
// const d = date.split("(")[0]
const totalBillAmount = parseInt(subTotal);
// const discounts = totalBillAmount-subTotal;
// console.log(greenItems);

  
    // Construct the HTML string for the bill
    const billHtml = `
      <div style="padding: 0; margin:0; background-color: white; font-family: Arial, sans-serif; width: 100%;">
        <h2 style="text-align: center; font-size: 1rem; font-weight: bold; margin-bottom: 1rem;">${hotelData.name}</h2>
        <p style="text-align: center; font-size: 0.7rem; line-height: 1rem;">${hotelData.address}</p>
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <p style="font-size: 0.7rem; margin: 0.3rem 0;"><strong>Date:</strong> ${`${day} / ${month} / ${year} : ${time}`} </p>
        <p style="font-size: 0.9rem; margin: 0.5rem 0;"><strong>Table:</strong> TABLE ${roomData.table}<strong>      Bill No: </strong> ${billNo}</p>
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <div>
          <table style="width: 100%; font-size: 0.7rem; border-collapse: collapse;">
            <thead>
              <tr>
                <th style="text-align: left;">Item</th>
                <th style="text-align: right;">Qty.</th>
                <th style="text-align: right;">Price</th>
                <th style="text-align: right;">Amount</th>
                
              </tr>
            </thead>
            <tbody>
              ${greenItems.map(item => `
                <tr>
                  <td style="padding: 0.25rem 0;"><strong>${item.name}</strong></td>
                  <td style="text-align: right;">${item.quantity}</td>
                  <td style="text-align: right;">${item.price ? item.price:item.amount}</td>
                  <td style="text-align: right;">${parseInt(item.quantity)*parseInt(item.price ? item.price:item.amount)}</td>

                </tr>
              `).join('')}
            </tbody>
          </table>
        </div>
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <p style="text-align: right; font-size: 0.9rem; margin: 0.5rem 0;"><strong>Sub Total:</strong> ₹${totalBillAmount+discount}.00</p>
       ${discount?` <p style="text-align: right; font-size: 0.9rem; margin: 0.5rem 0;"><strong>Discount:</strong> ₹${discount}.00</p>`:"" } 
        <p style="text-align: right; font-size: 0.9rem; margin: 0.5rem 0;"><strong>Grand Total:</strong> ₹${totalBillAmount}.00</p>
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <p style="text-align: center; font-size: 0.7rem; margin: 0.5rem 0;">Thank you for visiting!</p>
      </div>
    `;
  
    // Write the HTML to the new window
    WinPrint.document.write(billHtml);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
  };
  
  