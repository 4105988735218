import React, { useState } from "react";

const ItemGrid = ({ items, onItemSelect }) => {
  const [selectedVariants, setSelectedVariants] = useState({}); // Track selected variant per item

  // Handle variant selection
  const handleVariantSelect = (itemId, variantId) => {
    setSelectedVariants({
      ...selectedVariants,
      [itemId]: variantId,
    });
  };

  // Handle item selection with selected variant
  const handleItemClick = (item) => {
    const selectedVariant = selectedVariants[item.id];
    console.log(item,"............orderd Item .....");
    
    if (item.variant.length>0 && !selectedVariant) {
      alert("Please select a variant.");
      return;
    }
    
    const selectedItem = selectedVariant
      ? {
          ...item,
          selectedVariant: item.variant.find((v) => v.id === selectedVariant),
        }
      : item;
      
    onItemSelect(selectedItem);
  };

  return (
    <div className="grid grid-cols-3 gap-4">
      {items.map((item, index) => (
        <div
          key={index}
          className="bg-white p-4 border border-gray-300 rounded shadow-md hover:bg-gray-100"
        >
          <h3>{item.name}</h3>
          {/* <p>Price: ₹{item.price}</p> */}
          
          {item.variant && (
            <div className="mt-2">
              {/* <p>Select Variation:</p> */}
              {item.variant.map((variant) => (
                <label key={variant.id} className="block">
                  <input
                    type="radio"
                    name={`variant-${item.id}`}
                    value={variant.id}
                    checked={selectedVariants[item.id] === variant.id}
                    onChange={() => handleVariantSelect(item.id, variant.id)}
                  />
                  {variant.varientName} 
                </label>
              ))}
            </div>
          )}

          <button
            className="mt-2 bg-blue-500 text-white px-4 py-2 rounded"
            onClick={() => handleItemClick(item)}
          >
            Add to Cart
          </button>
        </div>
      ))}
    </div>
  );
};

export default ItemGrid;
