import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { OnBoardRes } from './AuthGard.Service.js';

const SignUp = () => {
  const [restaurantName, setRestaurantName] = useState('');
  const [restaurantAddress, setRestaurantAddress] = useState('');
  const [restaurantDescription, setRestaurantDescription] = useState('');
  const [restaurantTimeFrom, setRestaurantTimeFrom] = useState('');
  const [restaurantTimeTo, setRestaurantTimeTo] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [adminContact, setAdminContact] = useState('');
  const [logo, setLogo] = useState(null);
  const [noOfRooms, setNoOfRooms] = useState(1);
  const [rooms, setRooms] = useState([{ roomName: '', tables: null, isAc: false }]);
  const [currentSection, setCurrentSection] = useState(1);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogoUpload = (e) => {
    setLogo(e.target.value);
  };

  const handleSignUp = async () => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError('Please enter a valid email address.');
    } else if (password.length < 6) {
      setError('Password must be at least 6 characters long.');
    } else if (!restaurantName || !restaurantAddress || !restaurantDescription || !restaurantTimeFrom || !restaurantTimeTo || !adminContact || !logo) {
      setError('Please fill in all the fields and upload a logo.');
    } else {
      setError('');

      const requestBody = {
        name: restaurantName,
        address: restaurantAddress,
        description: restaurantDescription,
        timingFrom: restaurantTimeFrom,
        timingTo: restaurantTimeTo,
        email: email,
        password: password,
        adminContact: adminContact,
        logo: logo,
        noOfRooms: noOfRooms,
        roomData: rooms.map(room => ({
          roomName: room.roomName,
          noOfTables: room.tables,
          isAC: room.isAc
        }))
      };

      const data = await OnBoardRes(requestBody);
      console.log(data);
      
      if(data.error){
       
        setError(data.error.message || 'Failed to register restaurant.');
      }else{
        alert('Restaurant registered successfully! Please log in.');
        navigate('/login');
      }
     
    }
  };

  const handleRoomChange = (index, key, value) => {
    const updatedRooms = [...rooms];
    updatedRooms[index][key] = value;
    setRooms(updatedRooms);
  };

  const handleNoOfRoomsChange = (e) => {
    const count = parseInt(e.target.value, 10);
    setNoOfRooms(count);
    setRooms(Array.from({ length: count }, () => ({ roomName: '', tables: null, isAc: false })));
  };

  const nextSection = () => {
    setCurrentSection((prev) => Math.min(prev + 1, 3));
  };

  const previousSection = () => {
    setCurrentSection((prev) => Math.max(prev - 1, 1));
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="flex justify-center p-4 bg-gray-100">
        <p>With our basic version, you can manage up to 3 rooms and 45 tables, including 1 air-conditioned room.</p>
      </div>
      <div className="flex-grow overflow-y-auto flex flex-col items-center justify-center">
        {currentSection === 1 && (
          <div className="w-full max-w-lg p-6 bg-white shadow-md rounded">
            <h1 className="text-3xl mb-6">Restaurant Details</h1>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <input
              type="text"
              placeholder="Name of the Restaurant"
              className="border p-3 mb-4 w-full"
              value={restaurantName}
              onChange={(e) => setRestaurantName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Address of the Restaurant"
              className="border p-3 mb-4 w-full"
              value={restaurantAddress}
              onChange={(e) => setRestaurantAddress(e.target.value)}
            />
            <textarea
              placeholder="Description of the Restaurant"
              className="border p-3 mb-4 w-full"
              value={restaurantDescription}
              onChange={(e) => setRestaurantDescription(e.target.value)}
            />
            <label>Timing </label>
            <div className="flex mb-4">
              <input
                type="time"
                placeholder="Opening Time"
                className="border p-3 w-1/2 mr-2"
                value={restaurantTimeFrom}
                onChange={(e) => setRestaurantTimeFrom(e.target.value)}
              />
              To:
              <input
                type="time"
                placeholder="Closing Time"
                className="border p-3 w-1/2"
                value={restaurantTimeTo}
                onChange={(e) => setRestaurantTimeTo(e.target.value)}
              />
            </div>
            <div className="flex justify-between">
              <button onClick={nextSection} className="bg-blue-500 text-white px-4 py-2 rounded">
                Next
              </button>
            </div>
          </div>
        )}

        {currentSection === 2 && (
          <div className="w-full max-w-lg p-6 bg-gray-50 shadow-md rounded">
            <h1 className="text-3xl mb-6">Account Details</h1>
            <input
              type="email"
              placeholder="Email"
              className="border p-3 mb-4 w-full"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              className="border p-3 mb-4 w-full"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              type="text"
              placeholder="Admin Contact"
              className="border p-3 mb-4 w-full"
              value={adminContact}
              onChange={(e) => setAdminContact(e.target.value)}
            />
            <input
              type="text"
              // accept="image/*"
              placeholder="Logo or Image link"
              value={logo}
              className="border p-3 mb-4 w-full"
              onChange={handleLogoUpload}
            />
            <div className="flex justify-between">
              <button onClick={previousSection} className="bg-gray-500 text-white px-4 py-2 rounded">
                Back
              </button>
              <button onClick={nextSection} className="bg-blue-500 text-white px-4 py-2 rounded">
                Next
              </button>
            </div>
          </div>
        )}

        {currentSection === 3 && (
          <div className="w-full max-w-lg p-6 bg-white shadow-md rounded">
            <h1 className="text-3xl mb-6">Room Details</h1>
            <label className="inline-flex items-center mb-4">
              No of Rooms
            </label>
            <input
              type="number"
              placeholder="Number of Rooms"
              className="border p-3 mb-4 w-full"
              value={noOfRooms}
              onChange={handleNoOfRoomsChange}
              min={1}
              max={10}
            />
            <div className="max-h-96 overflow-y-auto">
              {rooms.map((room, index) => (
                <div key={index} className="mb-4">
                  <h5>Info Room {index+1}</h5>
                  <input
                    type="text"
                    placeholder={`Room ${index + 1} Name`}
                    className="border p-3 mb-2 w-full"
                    value={room.roomName}
                    onChange={(e) => handleRoomChange(index, 'roomName', e.target.value)}
                  />
                  <input
                    type="number"
                    placeholder={`Tables in Room ${index + 1}`}
                    className="border p-3 mb-2 w-full"
                    value={room.tables}
                    onChange={(e) => handleRoomChange(index, 'tables', parseInt(e.target.value, 10))}
                    min={0}
                  />
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      checked={room.isAc}
                      onChange={(e) => handleRoomChange(index, 'isAc', e.target.checked)}
                    />
                    <span className="ml-2">Air-Conditioned</span>
                  </label>
                </div>
              ))}
            </div>
            <div className="flex justify-between mt-4">
              <button onClick={previousSection} className="bg-gray-500 text-white px-4 py-2 rounded">
                Back
              </button>
              <button onClick={handleSignUp} className="bg-green-500 text-white px-4 py-2 rounded">
                Sign Up
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-center p-4 bg-gray-100">
        <Link to="/login" className="text-blue-500">
          Already have an account? Log in
        </Link>
      </div>
    </div>
  );
};

export default SignUp;
