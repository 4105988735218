import { getMessaging, getToken } from '@firebase/messaging';
import React, { useState, useEffect } from 'react'; // Import useEffect
import { Link, useNavigate } from 'react-router-dom';
import { LogInAdminRes } from './AuthGard.Service';
import { requestFcmToken } from '../../utils/firebaseUtils';
// import io from 'socket.io-client'; // Import socket.io-client

// const socket = io('http://localhost:3000'); // Replace with your server URL

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Listen for the 'adminLogin' event from the server
    // socket.on('adminLogin', (data) => {
    //   alert(`Admin logged in: ${data.name}`);
    //   console.log('Admin login event received:', data);
    // });

    // Clean up the socket connection when the component unmounts
    // return () => {
    //   socket.off('adminLogin');
    // };
  }, []);

  const handleLogin = async () => {
    const brkey = await requestFcmToken();

    const { data } = await LogInAdminRes({ username, password, key: brkey });

    if (data.token) {
      localStorage.setItem('authToken', data.token);
      localStorage.setItem('address', data.address);
      localStorage.setItem('name', data.name);
      localStorage.setItem("email",username)
      navigate('/table-overview');
      window.location.reload();
    } else {
      setError('Invalid credentials');
    }
  };

  const handleSignUp = () => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError('Please enter a valid email address.');
    } else if (password.length < 6) {
      setError('Password must be at least 6 characters long.');
    } else {
      setError('');
      alert('Sign up successful! Please log in.');
      setIsSignUp(false);
      setUsername('');
      setPassword('');
      setEmail('');
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-white p-6 rounded shadow-md w-96">
        <h1 className="text-2xl mb-4">{isSignUp ? 'Sign Up' : 'Login'}</h1>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {isSignUp && (
          <input
            type="email"
            placeholder="Email"
            className="border p-2 mb-4 w-full"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        )}
        <input
          type="text"
          placeholder="Username"
          className="border p-2 mb-4 w-full"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          className="border p-2 mb-4 w-full"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {isSignUp ? (
          <button onClick={handleSignUp} className="bg-blue-500 text-white p-2 rounded w-full">
            Sign Up
          </button>
        ) : (
          <button onClick={handleLogin} className="bg-green-500 text-white p-2 rounded w-full">
            Login
          </button>
        )}
        <Link to="/signup">
          <p className="mt-4 text-blue-500 cursor-pointer">
            If you are a new restaurant owner, please sign up with us
          </p>
        </Link>
      </div>
    </div>
  );
};

export default Login;
