import React from 'react'

const CancleKOTDoted = ({item}) => {
  return (
    <li className="flex justify-between items-center mb-2 line-through">
    <div>
      <h4>{item.name}</h4>
    </div>
    <div className="flex items-center">
      {/* <h4>{item.}</h4> */}
    </div>
  </li>
  )
}

export default CancleKOTDoted