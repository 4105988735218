import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const ExtraItems = ({ item, onQuantityChange, onDeleteItem }) => {
  console.log(item);
  
  return (
    <li className="flex justify-between items-center mb-2">
      <div className="max-w-40 min-w-40">
        <h4>{item.name}</h4>
        {item.selectedVariant && (
          <p className="text-sm text-gray-500">
            {item.selectedVariant.varientName}
          </p>
        )}
      </div>
     
      <div className="flex items-center">
       
        <span className="mx-2">{item.qty}</span>
        
        <h4 className="pl-6 min-w-5">₹ {parseFloat(item.selectedVariant? item.selectedVariant.varientAmount:item.price)*parseFloat(item.qty) }</h4>
        <button
        disabled
          className="ml-4 text-red-500"
          
        >
          Extra
        </button>
      </div>
    </li>
  );
};

export default ExtraItems;
