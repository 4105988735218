import React from "react";

const CategoryList = ({ categories, selectedCategory, onCategorySelect }) => {
  return (
    <div className="h-full overflow-y-auto">
      <ul className="flex flex-col p-4">
        {categories &&categories.map((category, index) => (
          <li
            key={index}
            onClick={() => onCategorySelect(category)}
            className={`cursor-pointer py-2 px-4 mb-2 text-left ${
              selectedCategory.name === category.name
                ? "bg-blue-400 text-white"
                : "bg-white text-gray-700"
            } hover:bg-gray-100 rounded`}
          >
            {category.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryList;
