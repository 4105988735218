import React, { useState } from "react";
import CategoryList from "./CategoryList";
import ItemGrid from "./ItemGrid";
import Cart from "./Cart";
import OrderSummary from "./OrderSummary";
import { createOrderByRestarunt } from "../../Service/KotStatusChange.service";
import { printKOT } from "../../../Print/PrintKOT";
import { cancleAndUpdate } from "../../../Print/CancleAndUpdateKOT";
import { addExtraAmount } from "./MenuAppServices/Manuapp.services";

const MenuApp = ({
  data,
  menu,
  bookedOrder: initialBookedOrder,
  closeEdit,
  billNo,
  oldKOTNotPrinted,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(
    menu && menu.length > 0 ? menu[0] : { name: "", items: [] }
  );
  const [cartItems, setCartItems] = useState([]);
  const [bookedOrder, setBookedOrder] = useState(initialBookedOrder);
  const [cancleKoTItem, setCancleKOTitem] = useState([]);
  const [error, setError] = useState("");
  const [itemSearchQuery, setItemSearchQuery] = useState(""); // Search query for items
  const [categorySearchQuery, setCategorySearchQuery] = useState(""); // Search query for categories

  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [extraCartItems, setExtraCartItems] = useState([]);

  const handleItemClick = (item) => {
    console.log(item, "va");
  
    const existingItem = cartItems.find(
      (cartItem) =>
        cartItem.name === item.name &&
        cartItem.selectedVariant?.id === item.selectedVariant?.id // Check if the variant is the same
    );
  
    if (existingItem) {
      // If the item with the same variant already exists, update the quantity
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.name === item.name &&
          cartItem.selectedVariant?.id === item.selectedVariant?.id
            ? { ...cartItem, qty: cartItem.qty + 1 }
            : cartItem
        )
      );
    } else {
      // If it's a new item or variant, add it to the cart with qty = 1
      setCartItems([...cartItems, { ...item, qty: 1 }]);
    }
  };
  

  const handleQuantityChange = (name, variantId, qty) => {
    setCartItems(
      cartItems.map((cartItem) =>
        cartItem.name === name && cartItem.selectedVariant?.id === variantId
          ? { ...cartItem, qty }
          : cartItem
      )
    );
  };
  
  const handleDeleteItem = (name, variantId) => {
    setCartItems(
      cartItems.filter(
        (cartItem) => 
          cartItem.name !== name || cartItem.selectedVariant?.id !== variantId
      )
    );
  };
  

  const handleCloseCart = () => {
    setCartItems([]);
    closeEdit();
  };

  const total = cartItems?.reduce(
    (acc, item) => acc + item.price * item.qty,
    0
  );

  const convertCartToOrderFormat = (cartItems) => {
    return cartItems?.map((item) => ({
      itemId: item.id,
      quantity: item.qty,
      varientid: item?.selectedVariant?.id,
    }));
  };

  const handleRestaurantOrder = async () => {
    if (extraCartItems.length > 0) {
      if (cartItems.length > 0) {
        const formattedItems = convertCartToOrderFormat(cartItems);
        try {
          const response = await createOrderByRestarunt({
            name: "NA",
            mobile: "NA",
            tableVPA: data.tableVPA,
            items: formattedItems,
          });
          console.log("Order Response: ", response);

          if (!response.data?.event) {
            setError("Something Wrong ");
            return;
          }
          setError("");
          window.location.reload();
        } catch (error) {
          console.error("Error while placing the order:", error);
          alert("Order submission failed due to a technical error.");
        }
        printKOT("checkIn KOT ", cartItems, data, extraCartItems);
      }else{
        printKOT("checkIn KOT", cartItems, data,extraCartItems);
      }
    }else{
      if (cartItems.length > 0) {
        const formattedItems = convertCartToOrderFormat(cartItems);
        try {
          const response = await createOrderByRestarunt({
            name: "NA",
            mobile: "NA",
            tableVPA: data.tableVPA,
            items: formattedItems,
          });
          console.log("Order Response: ", response);

          if (!response.data?.event) {
            setError("Something Wrong ");
            return;
          }
          setError("");
          printKOT("checkIn KOT", cartItems, data, extraCartItems);
          window.location.reload();
        } catch (error) {
          console.error("Error while placing the order:", error);
          alert("Order submission failed due to a technical error.");
        }
        
      }
    }
  };

  const cancleAndUpdateKOT = async () => {
    const formattedItems = convertCartToOrderFormat(cartItems);
    if (cartItems?.length > 0) {
      try {
        const response = await createOrderByRestarunt({
          name: "NA",
          mobile: "NA",
          tableVPA: data.tableVPA,
          items: formattedItems,
        });
        console.log("Order Response: ", response);

        if (!response.data?.event) {
          setError("Somthing Wrong ");
          return;
        }
        setError("");
        if (cartItems?.length > 0 && cancleKoTItem?.length > 0) {
          cancleAndUpdate(
            "Cancle & Update KOT",
            cartItems,
            data,
            cancleKoTItem,
            extraCartItems
          );
        } else if (cartItems?.length > 0 && cancleKoTItem?.length == 0) {
          cancleAndUpdate(
            "CheckIn KOT",
            cartItems,
            data,
            cancleKoTItem,
            extraCartItems
          );
        } else {
          cancleAndUpdate(
            "KOT",
            cartItems,
            data,
            cancleKoTItem,
            extraCartItems
          );
        }

        window.location.reload();
      } catch (error) {
        console.error("Error while placing the order:", error);
        alert("Order submission failed due to a technical error.");
      }
    } else {
      if (cartItems?.length > 0 && cancleKoTItem?.length > 0) {
        cancleAndUpdate(
          "Cancle & Update KOT",
          cartItems,
          data,
          cancleKoTItem,
          extraCartItems
        );
      } else if (cartItems?.length > 0 && cancleKoTItem?.length == 0) {
        cancleAndUpdate(
          "CheckIn KOT",
          cartItems,
          data,
          cancleKoTItem,
          extraCartItems
        );
      } else {
        cancleAndUpdate(
          "KOT",
          cartItems,
          data,
          cancleKoTItem,
          extraCartItems
        );
      }
      window.location.reload();
    }
  };

  const HandleKOTfilterItem = (itemid, item) => {
    const updatedBookedOrder = bookedOrder.filter(
      (i) => i.itemId !== itemid || i.name !== item.name
    );
  
    setBookedOrder(updatedBookedOrder);
    setCancleKOTitem((prevItems) => [...prevItems, item]);
  };
  

  // Filter all items from all categories when there's a search query
  const filteredItems = itemSearchQuery
    ? menu.flatMap((category) =>
        category.items.filter((item) =>
          item.name.toLowerCase().includes(itemSearchQuery.toLowerCase())
        )
      )
    : selectedCategory.items;

  // Filter categories based on the category search query
  const filteredCategories = menu?.filter((category) =>
    category.name.toLowerCase().includes(categorySearchQuery.toLowerCase())
  );
  const [extra, setExtrea] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Name:", name);
    console.log("data:", data);
    console.log("Print:", price);
    const added = await addExtraAmount({
      name: name,
      quantity: quantity,
      price: price,
      billNo: billNo,
      tableVPA: data.tableVPA,
    });

    if (added.data?.event) {
      setExtrea(false);
      setExtraCartItems((prv) => [
        ...prv,
        { name: name, qty: quantity, price: price },
      ]);
      cancleAndUpdate(
        "KOT",
        [{ name: name, qty: quantity, price: price }],
        data,
        cancleKoTItem,
        extraCartItems
      );
      window.location.reload();
    }
  };
  const handleSubmitSave = async (e) => {
    e.preventDefault();
    console.log("Name:", name);
    console.log("data:", data);
    console.log("Print:", price);
    const added = await addExtraAmount({
      name: name,
      quantity: quantity,
      price: price,
      billNo: billNo,
      tableVPA: data.tableVPA,
    });

    if (added.data?.event) {
      setExtrea(false);
      setExtraCartItems((prv) => [
        ...prv,
        { name: name, qty: quantity, price: price },
      ]);
      // cancleAndUpdate("KOT",[{name:name,qty:quantity,price:price}],data,cancleKoTItem)
    }
  };
  const saveOnly = async () => {
    const formattedItems = convertCartToOrderFormat(cartItems);
    try {
      const response = await createOrderByRestarunt({
        name: "NA",
        mobile: "NA",
        tableVPA: data.tableVPA,
        items: formattedItems,
      });
      console.log("Order Response: ", response);

      if (!response.data?.event) {
        setError("Something Wrong ");
        return;
      }
      setError("");
      // printKOT("check In", cartItems, data);
      window.location.reload();
    } catch (error) {
      console.error("Error while placing the order:", error);
      alert("Order submission failed due to a technical error.");
    }
  };
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex z-50">
      <div className="w-full h-full bg-white flex">
        {/* Category List */}
        <div className="w-1/4 bg-gray-200 p-4">
          {/* Search Bar for Categories */}
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search categories..."
              value={categorySearchQuery}
              onChange={(e) => setCategorySearchQuery(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>

          <CategoryList
            categories={filteredCategories}
            selectedCategory={selectedCategory}
            onCategorySelect={setSelectedCategory}
          />
        </div>

        {/* Item Grid */}
        <div className="w-2/3 bg-gray-200 p-4 overflow-y-auto">
          {/* Search Bar for Items */}
          <div className="mb-4 flex">
            <input
              type="text"
              placeholder="Search items..."
              value={itemSearchQuery}
              onChange={(e) => setItemSearchQuery(e.target.value)}
              className="w-full p-2 border rounded"
            />
            <button
              className="min-w-20 bg-blue-400 ml-5 rounded"
              onClick={() => {
                setExtrea(true);
              }}
            >
              Add Extra
            </button>
          </div>
          {extra && (
            <form className="flex items-center space-x-4 mb-10">
              <input
                type="text"
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="border p-2 rounded w-48"
              />
             <input
  type="number"
  placeholder="Enter Quantity"
  value={quantity}
  onChange={(e) => {
    const value = Math.min(Math.max(e.target.value, 0), 50); // Restrict between 0 and 50
    setQuantity(value);
  }}
  className="border p-2 rounded w-30"
/>

<label className="flex items-center space-x-2">
  <input
    type="number"
    value={price}
    onChange={(e) => {
      const value = Math.min(Math.max(e.target.value, 0), 10000); // Restrict between 0 and 10000
      setPrice(value);
    }}
    className="border p-2 rounded w-24"
    placeholder="Enter price"
/>
</label>

              <span>
                Rs:{" "}
                {quantity && price && parseFloat(price) * parseFloat(quantity)}
              </span>
              <div className="flex justify-end"></div>
              <button
                onClick={handleSubmit}
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-1 rounded"
              >
                Save & Print
              </button>
              <button
                onClick={handleSubmitSave}
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Save
              </button>
            </form>
          )}

          {filteredItems && (
            <ItemGrid items={filteredItems} onItemSelect={handleItemClick} />
          )}
        </div>

        {/* Cart and Order Summary */}
        <div className="w-1/3 bg-white p-6 border-l border-gray-300">
          <Cart
            cartItems={cartItems}
            onQuantityChange={handleQuantityChange}
            onDeleteItem={handleDeleteItem}
            onCloseCart={handleCloseCart}
            bookedOrder={bookedOrder}
            extraCartItems={extraCartItems}
            billNo={billNo}
            filterItem={HandleKOTfilterItem}
          />
          <OrderSummary
            cartItems={cartItems}
            total={total}
            itemCount={cartItems.length}
            bookOrder={handleRestaurantOrder}
            billNo={billNo}
            saveOnly={saveOnly}
            cancleAndUpdateKOT={cancleAndUpdateKOT}
          />
          {error && <p className="text-red-500 ">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default MenuApp;
