import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Header1 from '../Header/Header2';
import ParcelBox from './Parcletable/ParcelBox';

export const ParcelMain = () => {
    const [activeTab, setActiveTab] = useState('parcel');
    const [orderParcel, setOrderParcel] = useState([{orderNo:1},{orderNo:2},{orderNo:4}]);
  const navigate = useNavigate();

  return (
    <>
    <Header1 />
      <div className="flex border-b border-gray-300 mb-4">
      <button
          className={`py-2 px-4 text-lg font-semibold ${activeTab === 'dine-in' ? 'border-b-2 border-yellow-500 text-gray-800' : 'text-gray-600'}`}
          onClick={() => {setActiveTab('dine-in')
            navigate('/table-overview');
          }}
        >
          Dine In
        </button>
        <button
          className={`py-2 px-4 text-lg font-semibold ${activeTab === 'parcel' ? 'border-b-2 border-yellow-500 text-gray-800' : 'text-gray-600'}`}
          onClick={() =>{ setActiveTab('parcel')
            navigate('/order-parcel');
            
          }}
        >
          Parcel
        </button>
       
      </div>
      <h1>Work In Progress......</h1>

      {/* <div className="bg-white shadow-lg rounded-lg p-6 mt-6">
        
          <div className="mb-4">
            <div className="space-x-4">
              <span className="text-gray-500">Legend:</span>
              <span className="inline-flex items-center">
                <span className="w-4 h-4 bg-gray-300 inline-block mr-1 rounded"></span>{" "}
                Blank Table
              </span>
              <span className="inline-flex items-center">
                <span className="w-4 h-4 bg-blue-500 inline-block mr-1 rounded"></span>{" "}
                Request KOT
              </span>
              <span className="inline-flex items-center">
                <span className="w-4 h-4 bg-green-500 inline-block mr-1 rounded"></span>{" "}
                Printed Table
              </span>
            </div>
          </div>

          <div className="flex justify-centre	">
          

            <div className="w-1/4 pr-4 mr-20">
              <div className="bg-gray-100 p-4 rounded-lg shadow-md">
            <h3 className="text-lg font-bold mb-4 centre ">Parcel</h3>
                </div>
              </div>
            
        

          
            <div className="w-3/4">
              <div className="grid grid-cols-5 gap-4">

        <ParcelBox  orderParcel={orderParcel}/>
                
                
              </div>
            </div>
           
          </div>
        </div> */}
    </>
  )
}
