import { useState } from "react";

import MenuApp from "./MenuApp/MenuApp";

const CategorySelection = ({data,menu,bookedOrder,closeEdit,billNo,oldKOTNotPrinted}) => {  
  console.log(oldKOTNotPrinted,".....booked order ");
  
    return (
    //   <div className="flex h-screen">
    //     {/* Left side: CategoryList */}
    //     <CategoryList categories={categories} onItemSelected={handleItemSelected} />
  
    //     {/* Right side: SelectedItemsPanel */}
    //     {isPanelOpen && (
    //       <SelectedItemsPanel
    //         selectedItems={selectedItems}
    //         onClose={handleClose}
    //         onQuantityChange={handleQuantityChange}
    //         onDelete={handleDeleteItem}
    //       />
    //     )}
    //   </div>
    <MenuApp data={data} menu={menu}bookedOrder={bookedOrder} closeEdit={closeEdit} billNo={billNo} oldKOTNotPrinted={oldKOTNotPrinted}/>
    );
  };
  
  export default CategorySelection;