import React, { useEffect, useState } from "react";
import { PrintBills } from "../../Print/PrintBill";
import { billGenerate, getbillGenerate } from "./Services/Payment.service";
import { paymentMethodAPI } from "../Service/KotStatusChange.service";

const OrderPayment = ({
  order,
  roomData,
  hotelData,
  DoneOrderBYbillNo,
  waiterlist,
}) => {
  const [paymentMethod, setPaymentMethod] = useState();
  const [selectedWaiter, setSelectedWaiter] = useState(""); // Will hold fetched waiter ID
  const [discount, setDiscount] = useState(0);
  const [discountFlat, setDiscountFlat] = useState(0); // Will hold fetched flat discount
  const [tips, setTips] = useState("");
  const [partialPayment, setPartialPayment] = useState({
    cash: 0,
    online: 0,
    card: 0,
  });
  const [billPrinted, setBillPrinted] = useState(false);
  const [error, setError] = useState("");
  const [setExtraDiscounts, setExtraDiscount]= useState(0);
  const[paymentbtn , setDisablePaymentButton]= useState(false)
  
  console.log(order[0].amount, "...........//.....amounts to prive ");

  // Fetch bill data when order changes
  useEffect(() => {
    const fetchBill = async () => {
      const ans = await getbillGenerate({ billNo: order[0]?.billNo });
      if (ans.data.event) {
        setSelectedWaiter(ans.data.items?.waiterid); // Set fetched waiter ID
        setDiscountFlat(ans.data.items?.discount); // Set fetched discount
        setBillPrinted(true);
        // console.log(ans.data.event, ",,,,,,,,,,,,,,,,");
      }
    };
    //  fetchBill();
    fetchBill();
  }, [order]);

  const handleGenerateBill = async () => {
    const totalAmount = Math.round(
      order[0].amount -
        (order[0].amount * (discount || 0)) / 100 -
        parseInt(discountFlat || 0)
    );
    const discounts = order[0].amount - totalAmount;
    

    const itmans = await billGenerate({
      billNo: order[0].billNo,
      waiterid: selectedWaiter,
      discount: discounts,
    });
    PrintBills(
      order[0].oldOrder,
      totalAmount,
      discounts,
      hotelData,
      roomData,
      order[0].billNo
    );
    // if(at)
    setBillPrinted(true);
    window.location.reload();
  };

  const ReprinthandleGenerateBill = async () => {
    const totalAmount = Math.round(order[0].amount);
    console.log(discount, discountFlat);

    const discounts = parseInt(discountFlat || 0);
    PrintBills(
      order[0].oldOrder,
      totalAmount,
      discounts,
      hotelData,
      roomData,
      order[0].billNo
    );
  };

  const handlePaymentComplete = async () => {
    if (!paymentMethod) {
      setError("Please Select Payment Method");
      // alert("Please Select Payment Method ")
      return;
    }
    setDisablePaymentButton(true)
    const billNo = order[0].billNo;
    console.log("Payment Data:", billNo);
    const methods = [];
    let total=0;
    if (paymentMethod == "partial") {

      methods.push({
        name: "cash",
        amount: partialPayment.cash,
      });
      total=total+parseInt(partialPayment.cash)
      methods.push({
        name: "online",
        amount: partialPayment.online,
      });
      total=total+parseInt(partialPayment.online)
      methods.push({
        name: "card",
        amount: partialPayment.card,
      });
      total=total+parseInt(partialPayment.card)
      
    } else {
      methods.push({
        name: paymentMethod,
        amount: "",
      });
    }
    console.log("......", methods, billNo);
    if(paymentMethod == "partial" && (parseFloat(order[0].amount)-parseFloat(setExtraDiscounts))!= total){
      alert ("Total Bill Not Correct ");
      setDisablePaymentButton(false);
      return;

      
    }

    const data = await paymentMethodAPI({ billNo, methods, tips: tips ,extraDiscount:setExtraDiscounts,paymentname:paymentMethod});
    console.log(data, "...........");
    if (data.data?.event) {
      // setPaymetMethod(true)
      // const savetips = await
      DoneOrderBYbillNo(billNo);
    }

    // Handle payment completion (e.g., API call, save to state, etc.)
  };


  return (
    <div className="order-payment w-full max-w-md mx-auto p-4 bg-white shadow-lg rounded-md">
      <h3 className="text-lg font-semibold mb-4">Select Waiter</h3>
      <select
        value={selectedWaiter} // The selected waiter reflects the fetched value
        onChange={(e) => setSelectedWaiter(e.target.value)}
        className="block w-full mb-4 p-2 border border-gray-300 rounded"
      >
        <option value="">Select Waiter</option>
        {waiterlist.map((waiter) => (
          <option key={waiter.id} value={waiter.id}>
            {waiter.name}
          </option>
        ))}
      </select>

      <h3 className="text-lg font-semibold mb-4">Discount</h3>
      <div className="flex justify-between">
        {!billPrinted && (
          <>
            <div>
              Flat Discount
              <input
                type="number"
                value={discountFlat} // Reflect the fetched flat discount
                onChange={(e) => setDiscountFlat(e.target.value)}
                className="block w-3/4 mb-4 p-2 border border-gray-300 rounded"
                placeholder="Flat Discount"
              />
            </div>
            <div>
              Percentage Discount
              <input
                type="number"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
                className="block w-3/4 mb-4 p-2 border border-gray-300 rounded"
                placeholder="Percentage Discount"
              />
            </div>

          </>
        )}
        
        {billPrinted && <div className="flex justify-between"><div>Discount : Rs {discountFlat}</div>
       <div className="pl-8 ">
        Extra Discount
       <input
                type="number"
                value={setExtraDiscounts}
                onChange={(e) => setExtraDiscount(e.target.value)}
                className="block w-3/4 mb-4 p-2 border border-gray-300 rounded"
                placeholder="Percentage Discount"
              />
       </div>
        </div>}
      </div>
      <div>
            {order[0].amount &&!billPrinted && (
              <>
                <h2>Sub Amount {order[0].amount}</h2>
               
                  <>
                    <h1>
                      Discount:{" "}
                      {(order[0].amount * (discount || 0)) / 100 +
                        parseInt(discountFlat || 0) +parseFloat(setExtraDiscounts ||0)}
                    </h1>
                    <h2>
                      Total Amount :{" "}
                      {parseFloat(order[0].amount)-(order[0].amount * (discount || 0)) / 100 -
                        parseInt(discountFlat || 0)-parseFloat(setExtraDiscounts ||0)}
                    </h2>
                  </>
                
              </>
            )}
            </div>

      {/* this is for afeter print bill------- */}
      {billPrinted && (
        <>
          <h3 className="text-lg font-semibold mb-4">Tips for Waiter</h3>
          <input
            type="number"
            value={tips}
            onChange={(e) => setTips(e.target.value)}
            className="block w-full mb-4 p-2 border border-gray-300 rounded"
            placeholder="Enter Tips"
          />

          <h3 className="text-lg font-semibold mb-4">Payment Method</h3>
          <select
            value={paymentMethod}
            onChange={(e) => {
              setPaymentMethod(e.target.value);
              setError("");
            }}
            className="block w-full mb-1 p-2 border border-gray-300 rounded"
          >
            <option value="">Select Payment Method</option>
            <option value="cash">Cash</option>
            <option value="online">Online</option>
            <option value="card">Card</option>
            <option value="partial">Partial</option>
            <option value="pending">Pending</option>

          </select>

          {paymentMethod === "partial" && (
            <div className="partial-payment mb-4">
              {["cash", "online", "card"].map((method) => (
                <label key={method} className="block mb-2">
                  {method.charAt(0).toUpperCase() + method.slice(1)}:
                  <input
                    type="number"
                    name={method}
                    value={partialPayment[method]}
                    onChange={(e) =>
                      setPartialPayment({
                        ...partialPayment,
                        [e.target.name]: e.target.value,
                      })
                    }
                    className="block w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </label>
              ))}
            </div>
          )}
          {error && <p className="text-red-500 mt-0 text-sm">{error}</p>}
          {order[0].amount && (
            <h4 className="mt-5">
              <strong>Total Amount :- ₹{parseFloat(order[0].amount)-parseFloat(setExtraDiscounts ||0)}</strong>
            </h4>
          )}
          <div className="actions flex justify-between mt-6">
            <button
              onClick={ReprinthandleGenerateBill}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Reprint Bill
            </button>
            <button
              onClick={handlePaymentComplete}
              disabled={paymentbtn}
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            >
              Done Order
            </button>
          </div>
        </>
      )}

      {!billPrinted && (
        <div className="actions flex justify-between mt-6">
          <button
            onClick={handleGenerateBill}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Generate Bill
          </button>
        </div>
      )}
    </div>
  );
};

export default OrderPayment;
