import React, { useEffect, useState } from "react";
import KOTSection from "./KOTSection";
import {
  getAllCategoryAndItem,
  getPublicItemBYVPA,
  getWaiterList,
  orderDone,
} from "./Service/KotStatusChange.service";

import OrderPayment from "./PaymentComponent/OrderPayment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import CategorySelection from "./OrderHandle/OrderHandle";
// import ActionButtons from './ActionButtons';

const AcceptOrder = ({ handleClosePrompt, data, printed, address }) => {
  const [onlineOrderKot, setOnlineOrderKot] = useState([]);
  const [waiterlist, setWaiterList] = useState([]);
  const [editOrder, setEditOrder] = useState(false);
  const [menuListForOrder, setMenuListForOrder] = useState();

  const [hotelData] = useState(address);
  const [availableItems, setAvailableItems] = useState();
  useEffect(() => {
    const fetch = async () => {
      const users = [];

      await printed?.map(async (ele) => {
        // console.log(ele,"..............user");

        const itmKOT = [];
        const printedITem = [];
        await ele?.items.map((da) => {
          if (da.status == "RequestKOT") {
            itmKOT.push({
              id: da.itemId,
              name: da.name,
              price: da.price,
              quantity: da.quantity,
              iskot: false,
              TotalAmount: ele.amount,
            });
          } else if (da.status == "KOTPrint") {
            printedITem.push({
              id: da.itemId,
              name: da.name,
              price: da.price,
              quantity: da.quantity,
              iskot: false,
              TotalAmount: ele.amount,
            });
          }
        });

        users.push({
          name: ele.name,
          billNo: ele.billNo,
          amount: ele.amount,
          tableVPA: ele.tableVPA,
          oldOrder: printedITem,
          Kotitem: itmKOT,
        });
      });

      setOnlineOrderKot(users);
      if (users.length <= 0) {
        setEditOrder(true);
      }

      const output = await getPublicItemBYVPA({ vpa: data.tableVPA });
      //   item
      console.log(output.data.data);
      setAvailableItems(output.data.data);

      const waiter = await getWaiterList();
      // console.log(waiter, "......");
      setWaiterList(waiter.data.waiters);
      const allitemsfororder = await getAllCategoryAndItem();
      // console.log(allitemsfororder.data.event, "......");
      if (allitemsfororder.data?.event) {
        setMenuListForOrder(allitemsfororder.data?.data);
      }
    };
    fetch();
  }, []);
  console.log(menuListForOrder, ".......");
 
  const [searchQuery, setSearchQuery] = useState("");

  // Filters items based on search query (by name or ID)
  const filteredItems =
    availableItems &&
    availableItems.filter(
      (item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.id.toString().includes(searchQuery)
    );
  const DoneOrderBYbillNo = async (billNo) => {
    // call APi Order Done
    await orderDone({ billNo: billNo });
    setOnlineOrderKot((prevOrders) =>
      prevOrders.filter((order) => order.billNo !== billNo)
    );
    handleClosePrompt();
    // console.log(billNo,onlineOrderKot," completeOrder");
  };
  const closeEdit = () => {
    setEditOrder(false);
  };
  return (
    <div className="p-2 bg-gray-100 min-h-full">
      {!editOrder && (
        <div className="flex items-center justify-between py-4 px-6 bg-gray-100">
          <h1 className="text-2xl font-semibold">
            Table {data.table}, {data.room}
          </h1>

          <button
            className="flex items-center bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition duration-200 ease-in-out"
            onClick={() => {
              if(onlineOrderKot[0]?.Kotitem.length>0){
                alert("Please Accept Digital Order !");
                return
              }
              setEditOrder(true)}}
          >
            <FontAwesomeIcon icon={faEdit} className="mr-2" />
            Edit Order
          </button>

         

          <button
            className="text-gray-600 hover:text-red-600 bg-transparent p-3 rounded-full transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-300"
            onClick={handleClosePrompt}
          >
            <FontAwesomeIcon icon={faTimes} size="2x" />{" "}
            {/* Increased icon size */}
          </button>
        </div>
      )}
      {editOrder && (
        <div className="flex items-center justify-between py-4 px-6 bg-gray-100">
          <h1 className="text-2xl font-semibold">
            Table {data.table}, {data.room}
          </h1>

          <button
            className="text-gray-600 hover:text-red-600 bg-transparent p-3 rounded-full transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-300"
            onClick={() => setEditOrder(false)}
          >
            <FontAwesomeIcon icon={faTimes} size="2x" />
          </button>
        </div>
      )}

      {editOrder && (
        <CategorySelection
          menu={menuListForOrder}
          data={data}
          bookedOrder={printed[0] ? printed[0].items : ""}
          closeEdit={closeEdit}
          billNo={printed[0] ? printed[0].billNo : ""}
          oldKOTNotPrinted ={onlineOrderKot[0]?.Kotitem}
        />
      )}
      {
        console.log(onlineOrderKot,"......./....")
        
      }

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-2">
        <div className="col-span-2">
          {onlineOrderKot &&
            onlineOrderKot.map((order, index) => (
              <KOTSection
                key={index}
                billNo={order.billNo}
                name={order.name}
                items={order.Kotitem}
                orderId={order.id}
                oldOrder={order.oldOrder}
                Amount={order.TotalAmount}
                roomData={data}
                hotelData={hotelData}
                DoneOrderBYbillNo={DoneOrderBYbillNo}
                vpa={data.tableVPA}
              />
            ))}
        </div>
        {onlineOrderKot[0] && (
          <div className="bg-white shadow rounded ">
            <OrderPayment
              order={onlineOrderKot}
              roomData={data}
              hotelData={hotelData}
              DoneOrderBYbillNo={DoneOrderBYbillNo}
              vpa={data.tableVPA}
              waiterlist={waiterlist}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AcceptOrder;
