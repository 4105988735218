import React, { useState } from 'react';

const SetGST = () => {
  const [gst, setGst] = useState('');
  const [category, setCategory] = useState('');

  const handleGstChange = (e) => {
    setGst(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleSave = () => {
    // Implement logic to save GST to the database or application state
    // console.log(`GST for ${category} set to: ${gst}%`);
    alert('GST saved successfully!');
  };

  return (
    <div className="mb-4">
      <h2 className="text-lg font-bold">Set GST Based on Category</h2>
      <input
        type="text"
        value={category}
        onChange={handleCategoryChange}
        placeholder="Enter Category"
        className="border p-2 w-full mb-2"
      />
      <input
        type="number"
        value={gst}
        onChange={handleGstChange}
        placeholder="Enter GST Percentage"
        className="border p-2 w-full mb-2"
      />
      <button
        onClick={handleSave}
        className="bg-blue-500 text-white px-4 py-2 rounded"
      >
        Save GST
      </button>
    </div>
  );
};

export default SetGST;
