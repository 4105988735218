

// Import your sound file
import ringSound from '../img/qrapid_tune_order.mp3';


const ring = new Audio(ringSound);
export const playRing = () => {
    ring.play();
  };

