import React, { useEffect, useState } from "react";
import SearchBar from "./SearchBar";
import OrderFilters from "./OrderFilters";
import OrderList from "./OrderList";
import MenuTabs from "./Sidebar";
import { getReportForCancle } from "./orderService/orderService";

const OrderHistory = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchId, setSearchId] = useState(""); // For filtering by ID
  const [activeFilter, setActiveFilter] = useState("All");
  const [activeTab, setActiveTab] = useState("Table Service");
  const [orders, setOrders] = useState([]);
  const [sortKey, setSortKey] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [paymentMethodFilter, setPaymentMethodFilter] = useState(""); // New state for payment method filter

  let filteredOrders = orders
  // Filter by ID and search term in items
  .filter(
    (order) =>
      (searchId === "" || order.id.toString().includes(searchId)) && // Filter by ID
      order.items.some((item) =>
        item.toLowerCase().includes(searchTerm.toLowerCase())
      )
  )
  // Filter by payment method
  .filter(
    (order) =>
      paymentMethodFilter === "" ||
      order.paymentMethod === paymentMethodFilter
  )
  // Sort by id in descending order (higher to lower)
  .sort((a, b) => b.id - a.id);
  const handleSort = (key) => {
    const order = sortKey === key && sortOrder === "asc" ? "desc" : "asc";
    setSortKey(key);
    setSortOrder(order);
  };

  useEffect(() => {
    const fetch = async () => {
      const ans = await getReportForCancle();
      console.log(ans.data);
      setOrders(ans.data);
    };
    fetch();
  }, []);

  const orderdelete = (billNo) => {
    console.log(billNo);
    window.location.reload();
  };

  return (
    <div className="p-0 w-full">
      <MenuTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <h1 className="text-2xl mb-4">{activeTab} Order History Last Day</h1>

      <SearchBar onSearch={setSearchId} />

      {/* Payment Method Filter Buttons */}
      <div className="mb-4">
        <label className="block mb-2">Filter by Payment Method:</label>
        <div className="flex space-x-2">
          <button
            onClick={() => setPaymentMethodFilter("")}
            className="px-4 py-2 rounded bg-green-500 text-white"
          >
            Show All
          </button>
          <button
            onClick={() => setPaymentMethodFilter("cash")}
            className={`px-4 py-2 rounded ${
              paymentMethodFilter === "cash"
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
          >
            Cash
          </button>
          <button
            onClick={() => setPaymentMethodFilter("card")}
            className={`px-4 py-2 rounded ${
              paymentMethodFilter === "card"
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
          >
            Card
          </button>
          <button
            onClick={() => setPaymentMethodFilter("online")}
            className={`px-4 py-2 rounded ${
              paymentMethodFilter === "online"
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
          >
            Online
          </button>
          <button
            onClick={() => setPaymentMethodFilter("Partial")}
            className={`px-4 py-2 rounded ${
              paymentMethodFilter === "Partial"
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
          >
            Partial
          </button>
        </div>
      </div>

      <OrderFilters
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
      />
      <OrderList
        orders={filteredOrders}
        onSort={handleSort}
        orderdelete={orderdelete}
      />
    </div>
  );
};

export default OrderHistory;
