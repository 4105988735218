import React, { useEffect, useState } from 'react';
import { addCategoryAPI, allCatrgories, deletItem } from './MenuService/Category.Service';
import { addItemAPI, getAllItems, outOfStock, updateITem } from './MenuService/Item.service';

// ItemCard displays individual item details
const ItemCard = ({ item, onEdit, onDelete,enActive }) => {
  // const[item, setItem]= useState(items);
  
  const outofStock = (value)=>{
    item.status=!item.status;
    enActive(value)

  }
  return (
    <div className="border rounded-lg p-4 flex flex-col items-center">
      <img src={item.image} alt={item.name} className="h-24 w-24 mb-2" />
      <h3 className="text-lg font-bold">{item.name}</h3>
      <p className="text-sm">{item.category}</p>
      <p className="text-sm">{item.description}</p>
      <p className="text-sm font-bold">RS{item.amount}</p>
      <div className="mt-4">
        <button
          onClick={() => onEdit(item)}
          className="bg-black  text-white px-4 py-2 rounded mr-2"
        >
          Edit
        </button>
        <button
          onClick={() => onDelete(item.id)}
          className="bg-black ml-5  text-white px-4 py-2 rounded"
        >
          Delete
        </button>

       {item.status && <button
          onClick={() => outofStock(item.id)}
          className="bg-black  ml-5 text-white px-4 py-2 rounded"
        >
         Out of Stock
        </button>}
        {!item.status && <button
          onClick={() => outofStock(item.id)}
          className="bg-red-300  ml-5 text-white px-4 py-2 rounded"
        >
         In Stock
        </button>}
      </div>
    </div>
  );
};

// EditItemForm allows for editing item details

const EditItemForm = ({ item, categories, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    ...item,
    categoryId: item.categoryId || '',
    price: item.price || '',
    halfPrice: item.halfPrice || '',
    acPrice: item.acPrice || '',
    itmNo: item.itmNo || '',
    imgUrl: item.imgUrl || '',
    varient: item.varient || { status: false, items: [] },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleVariationChange = (index, field, value) => {
    const newVariants = [...formData.varient.items];
    newVariants[index][field] = value;
    setFormData({
      ...formData,
      varient: { ...formData.varient, items: newVariants },
    });
  };

  const handleAddVariation = () => {
    setFormData({
      ...formData,
      varient: {
        ...formData.varient,
        status: true,
        items: [...formData.varient.items, { name: '', amount: '' }],
      },
    });
  };

  const handleRemoveVariation = (index) => {
    const newVariants = formData.varient.items.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      varient: {
        ...formData.varient,
        items: newVariants,
      },
    });
  };
  const [desableSave, setDisableSave] = useState(false);

  const handleSave = async () => {
    if(!formData.categoryId && !formData.name && !formData.price){
      console.log(formData,",,,,,");
      
      alert("Add Required Data");
      return 0;
    }
    setDisableSave(true)
    const obj = {
      categoryId: formData.categoryId,
      name: formData.name,
      imgUrl: formData.imgUrl,
      itmNo: formData.itmNo,
      description: formData.description,
      price: formData.price,
      halfPrice: "",
      acPrice: formData.acPrice,
      varient: formData.varient,
    };

    if (formData.id) {
      const ans = await updateITem(obj, formData.id);
      if (ans.data.event) {
        onSave(formData);

        // Optionally, refresh or provide user feedback
      }
    } else {
      const ans = await addItemAPI(obj);
      if (ans.data.event) {
        onSave(formData);
        // Optionally, refresh or provide user feedback
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded shadow-lg max-w-screen-lg w-full">
        <h2 className="text-2xl font-bold mb-4">Add & Edit Item</h2>
        <form>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="mb-4">
              <label className="block text-sm font-medium">Item Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">Category</label>
              <select
                name="categoryId"
                value={formData.categoryId}
                onChange={handleChange}
                className="border p-2 w-full"
              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={`ttm_${category.id}`} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">Image URL</label>
              <input
                type="text"
                name="imgUrl"
                value={formData.imgUrl}
                onChange={handleChange}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">Description</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">Identification Number</label>
              <input
                type="text"
                name="itmNo"
                value={formData.itmNo}
                onChange={handleChange}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">Price</label>
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleChange}
                className="border p-2 w-full"
              />
            </div>
           
            <div className="mb-4">
              <label className="block text-sm font-medium">AC Price</label>
              <input
                type="number"
                name="acPrice"
                value={formData.acPrice}
                onChange={handleChange}
                className="border p-2 w-full"
              />
            </div>
            <div className="col-span-1 md:col-span-2">
              <h3 className="text-lg font-bold mb-2">Variations</h3>
              {formData.varient.items.map((variation, index) => (
                <div key={index} className="mb-4 flex items-center">
                  <input
                    type="text"
                    placeholder="Variation Name"
                    value={variation.name}
                    onChange={(e) => handleVariationChange(index, 'name', e.target.value)}
                    className="border p-2 w-full mr-2"
                  />
                  <input
                    type="number"
                    placeholder="Price"
                    value={variation.amount}
                    onChange={(e) => handleVariationChange(index, 'amount', e.target.value)}
                    className="border p-2 w-full mr-2"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveVariation(index)}
                    className="bg-red-500 text-white px-2 py-1 rounded"
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={handleAddVariation}
                className="bg-green-500 text-white px-4 py-2 rounded mt-2"
              >
                Add Variation
              </button>
            </div>
          </div>
          <div className="flex justify-end mt-6">
            <button
              type="button"
              disabled={desableSave}
              onClick={handleSave}
              className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
            >
              Save
            </button>
            <button
              type="button"
              onClick={onCancel}
              className="bg-gray-500 text-white px-4 py-2 rounded"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};



// AddNewCategory allows adding a new category
const AddNewCategory = ({ onSave, onCancel }) => {
  const [formData, setFormData] = useState({ category: '',link:'', type: '', associatedItems: [] });
  const [newItem, setNewItem] = useState({ name: '', amount: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleItemChange = (e) => {
    const { name, value } = e.target;
    setNewItem({
      ...newItem,
      [name]: value,
    });
  };

  const handleAddItem = () => {
    if (newItem.name && newItem.amount) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        associatedItems: [
          ...prevFormData.associatedItems,
          { id: Date.now(), name: newItem.name, amount: newItem.amount },
        ],
      }));
      setNewItem({ name: '', amount: '' });
    }
  };

  const handleRemoveItem = (itemId) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      associatedItems: prevFormData.associatedItems.filter((item) => item.id !== itemId),
    }));
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Add Category</h2>
        <form>
          <div className="mb-4">
            <label className="block text-sm font-medium">Category Name</label>
            <input
              type="text"
              name="category"
              value={formData.category}
              onChange={handleChange}
              className="border p-2 w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium">Image Link</label>
            <input
              type="text"
              name="link"
              value={formData.link}
              onChange={handleChange}
              className="border p-2 w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium">Type</label>
            <select
              name="type"
              value={formData.type}
              onChange={handleChange}
              className="border p-2 w-full"
            >
              <option value="">Select Type</option>
              <option value="true">Half/Full Item Based</option>
              <option value="false">Item Based</option>
            </select>
          </div>

          {formData.type === 'false' && (
            <div className="mb-4">
              <label className="block text-sm font-medium">Add Items</label>
              <div className="mb-4 flex space-x-2">
                <input
                  type="text"
                  name="name"
                  value={newItem.name}
                  onChange={handleItemChange}
                  placeholder="Item Name"
                  className="border p-1 w-30"
                />
                <input
                  type="number"
                  name="amount"
                  value={newItem.amount}
                  onChange={handleItemChange}
                  placeholder="Amount"
                  className="border p-1 w-30"
                />
                <button
                  type="button"
                  onClick={handleAddItem}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  + Add Item
                </button>
              </div>

              <div className="border p-2">
                {formData &&formData.associatedItems.map((item) => (
                  <div key={item.id} className="flex justify-between items-center mb-2">
                    <div>
                      <span>{item.name}</span> - <span>RS{item.amount}</span>
                    </div>
                    <button
                      type="button"
                      onClick={() => handleRemoveItem(item.id)}
                      className="bg-red-500 text-white px-2 py-1 rounded"
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => onSave(formData)}
              className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
            >
              Save
            </button>
            <button
              type="button"
              onClick={onCancel}
              className="bg-gray-500 text-white px-4 py-2 rounded"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// ItemManagement is the main component managing items and categories
const ItemManagement = () => {
  const [items, setItems] = useState([
  
    // Add more items here...
  ]);
  useEffect(()=>{
    const fetch = async()=>{
      const item = await getAllItems();
      // console.log(...item.data.menuItems);
      setItems(item.data.menuItems)
      

    }
    fetch();
  },[])

  const [editingItem, setEditingItem] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [addCategory, setAddCategory] = useState(false);
  const[categories, setCategories] = useState([{name:'All',id:0}])
  const maxVisibleCategories = 4;

  // const categories = ['All', 'Main Course', 'Appetizer', 'Dessert', 'Beverage'];

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const allc = await allCatrgories(); // Assuming allCatrgories is your API call function
        // console.log(allc);
        setCategories(() => [{name:'All',id:0}, ...allc.data.categories]);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);
  const handleEdit = (item) => {
    const ctId = categories.find((ele)=>ele.name==item.category);
    console.log(ctId,);
    
    let items = {
      name:item.name,
      categoryId: ctId?.id
      || '',
      price: item.amount || '',
      halfPrice: item.halfPrice || '',
      acPrice: item.acPrice || '',
      itmNo: item.identificationNo || '',
      imgUrl: item.image || '',
      varient: item.varient,
      description: item.description,
      // identificationNo:item.identificationNo

    }
    setEditingItem(items);

    console.log(item,"item ");

    
  };

  const handleSave = (updatedItem) => {
    setItems(items.map((item) => (item.id === updatedItem.id ? updatedItem : item)));
    setEditingItem(null);
  };

  const handleDelete = (id) => {
    setItems(items.filter((item) => item.id !== id));

    // /admin/deleteMenu
    deletItem(id)
    // console.log(id,"......................");
    
    // 
  };

  const handleCancel = () => {
    setEditingItem(null);
  };

  const handleSaveCategory = async(newCategory) => {
    // Handle saving new category
    const obj ={
      name:newCategory.category,link:newCategory.link, typeHalf:newCategory.type, items: newCategory.associatedItems
    }
    // console.log('Category saved:', newCategory);
    if(newCategory.category && newCategory.type){
      const ans = await addCategoryAPI(obj);
      // console.log(ans.data.event);

      
      if(ans.data.event){
       setAddCategory(false);
       window.location.reload();

     }
    }
    
  };

  const handleCancelCategory = () => {
    setAddCategory(false);
  };

  const filteredItems = items.filter(
    (item) =>
      (selectedCategory === 'All' || item.category === selectedCategory) &&
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleDropdownChange = (e) => {
    setSelectedCategory(e.target.value);
    setSearchTerm("")

  };
  const  enActive =async (value)=>{
// call incative api
const status= await outOfStock({itemid:value});
if(status.data.event){
  alert("Done");
  window.location.reload()
}

// /menu/outofstock

  }
  return (
    <div className="p-8">
      <div className="flex justify-between mb-6">
        <h1 className="text-2xl font-bold">Item Management</h1>
        <div>
          <button
            onClick={() =>
              setEditingItem({ id: null, name: '', category: '', description: '', amount: 0, image: '' })
            }
            className="bg-black text-white px-4 py-2 rounded mr-2"
          >
            + Add Item
          </button>
          <button
            onClick={() => setAddCategory(true)}
            className="bg-black text-white px-4 py-2 rounded"
          >
            + Add Category
          </button>
        </div>
      </div>

      <div className="flex justify-between mb-4">
      <div className="flex flex-col space-y-2">
      <div className="flex flex-wrap space-x-2">
        {categories &&categories.map((category, index) => {
          if (index < maxVisibleCategories) {
            // Render buttons for the first few categories
            return (
              <button
                key={category.id}
                onClick={() => setSelectedCategory(category.name)}
                className={`px-4 py-2 rounded ${
                  selectedCategory === category.name ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'
                }`}
              >
                {category.name}
              </button>
            );
          }
          return null; // Skip rendering for categories beyond the visible limit
        })}
        {categories &&categories.length > maxVisibleCategories && (
          <div className="relative">
            <select
              onChange={handleDropdownChange}
              value={selectedCategory}
              className="px-4 py-2 rounded bg-gray-200 text-black"
            >
              <option value="">Select Category</option>
              {categories.slice(4,).map((dropdownCategory) => (
                <option
                  key={`sl_${dropdownCategory.id}`}
                  value={dropdownCategory.name}
                >
                  {dropdownCategory.name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    </div>
        <input
          type="text"
          placeholder="Search items..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border p-2 rounded"
        />
      </div>

      <div className="grid grid-cols-3 gap-4">
        {filteredItems.map((item) => (
          <ItemCard key={item.id} item={item} onEdit={handleEdit} onDelete={handleDelete} enActive={enActive}/>
        ))}
      </div>

      {editingItem && <EditItemForm item={editingItem} onSave={handleSave} onCancel={handleCancel} categories={categories} />}
      {addCategory && <AddNewCategory onSave={handleSaveCategory} onCancel={handleCancelCategory} />}
    </div>
  );
};

export default ItemManagement;
