import { api } from "../../Environments/Environment.Test";

// /order/report/iscancle
const authUrl = `${api}/anylisis`

export function getReportForCancle() {
    // const navigate = useNavigate();
  
    return new Promise(async (resolve) => {
      try {
        const token = await localStorage.getItem("authToken");
        const response = await fetch(`${authUrl}/order/report/iscancle`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        //   body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        resolve({ data });
      } catch (error) {
        resolve({ error: error.message });
      }
    });
  }

//   /order/cancle/password

export function cancleOrder(requestBody) {
    // const navigate = useNavigate();
  
    return new Promise(async (resolve) => {
      try {
        const token = await localStorage.getItem("authToken");
        const response = await fetch(`${authUrl}/order/cancle/password`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        resolve({ data });
      } catch (error) {
        resolve({ error: error.message });
      }
    });
  }
  // /order/change/payment/method

  export function changePaymentMethod(requestBody) {
    // const navigate = useNavigate();
  
    return new Promise(async (resolve) => {
      try {
        const token = await localStorage.getItem("authToken");
        const response = await fetch(`${api}/restaurant/order/change/payment/method`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        resolve({ data });
      } catch (error) {
        resolve({ error: error.message });
      }
    });
  }

  export function extraItemgetByBill(requestBody) {
    // const navigate = useNavigate();
  
    return new Promise(async (resolve) => {
      try {
        const token = await localStorage.getItem("authToken");
        const response = await fetch(`${authUrl}/order/history/printbill/history`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        resolve({ data });
      } catch (error) {
        resolve({ error: error.message });
      }
    });
  }
