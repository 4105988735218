import React, { useState } from 'react';

const PaymentPopup = ({ onComplete,cancle }) => {
  const [paymentMethod, setPaymentMethod] = useState('');
  const [partialOnlineAmount, setPartialOnlineAmount] = useState('');
  const [partialCashAmount, setPartialCashAmount] = useState('');
  const [isPartial, setIsPartial] = useState(false);

  const handlePaymentChange = (e) => {
    const method = e.target.value;
    setPaymentMethod(method);
    if (method === 'partial') {
      setIsPartial(true);
    } else {
      setIsPartial(false);
    }
  };

  const handleComplete = () => {
    const paymentData = {
      method: paymentMethod,
      ...(isPartial && {
        onlineAmount: partialOnlineAmount,
        cashAmount: partialCashAmount,
      }),
    };
    onComplete(paymentData); // Call the onComplete function with payment data
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center w-80">
        <h2 className="text-xl font-semibold mb-4">Select Payment Method</h2>
        <select
          value={paymentMethod}
          onChange={handlePaymentChange}
          className="border border-gray-300 p-2 rounded w-full mb-4"
        >
          <option value="">Select Method</option>
          <option value="online">Online</option>
          <option value="card">Card</option>
          <option value="cash">Cash</option>
          <option value="partial">Partial Payment</option>
          <option value="pending">Pending Payment</option>
        </select>

        {isPartial && (
          <div className="space-y-4">
            <div>
              <label className="block text-left text-gray-700">Online/Card Amount:</label>
              <input
                type="number"
                value={partialOnlineAmount}
                onChange={(e) => setPartialOnlineAmount(e.target.value)}
                className="border border-gray-300 p-2 rounded w-full"
                placeholder="Enter amount"
              />
            </div>
            <div>
              <label className="block text-left text-gray-700">Cash Amount:</label>
              <input
                type="number"
                value={partialCashAmount}
                onChange={(e) => setPartialCashAmount(e.target.value)}
                className="border border-gray-300 p-2 rounded w-full"
                placeholder="Enter amount"
              />
            </div>
          </div>
        )}

<div className='flex justify-between'>

<button
          onClick={cancle}
          className="bg-blue-500 text-white px-4 py-2 rounded mt-6 hover:bg-blue-600 transition"
        >
          Back
        </button>
        <button
          onClick={handleComplete}
          className="bg-blue-500 text-white px-4 py-2 rounded mt-6 hover:bg-blue-600 transition"
        >
          Complete
        </button>
</div>
        
      </div>
    </div>
  );
};

export default PaymentPopup;
