import React, { useState } from 'react';

const SpecialOffer = () => {
  const [offer, setOffer] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleOfferChange = (e) => {
    setOffer(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleSendOffer = () => {
    // Logic to create a special offer and send a notification via WhatsApp
    const message = `Special Offer: ${offer}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="mb-4">
      <h2 className="text-lg font-bold">Special Offer Set for Food Item</h2>
      <input
        type="text"
        value={offer}
        onChange={handleOfferChange}
        placeholder="Enter Special Offer"
        className="border p-2 w-full mb-2"
      />
      <input
        type="tel"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        placeholder="Enter Customer Phone Number"
        className="border p-2 w-full mb-2"
      />
      <button
        onClick={handleSendOffer}
        className="bg-blue-500 text-white px-4 py-2 rounded"
      >
        Send Offer via WhatsApp
      </button>
    </div>
  );
};

export default SpecialOffer;
