export  const printKOT = (type,redItems,roomData,extraCartItems) => {
   
  const date = new Date();
  const day = date.getDate(); // Day of the month (1-31)
  const month = date.getMonth() + 1; // Month (0-11, so we add 1 to get 1-12)
  const year = date.getFullYear();
  const time = date.toLocaleTimeString()
  

    // const totalBills = 
    // console.log(redItems,".............");
    
    const KOT = `
      <div style="padding: 0; margin:0; background-color: white; font-family: Arial, sans-serif; width: 100%; max-width: 300px;">
        <h2 style="text-align: center; font-size: 1rem; font-weight: bold; margin-bottom: 1rem;">${type}</h2>
       
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <p style="font-size: 0.8rem; margin: 0.4rem 0;"><strong>${`${day} / ${month} / ${year} : ${time}`}</strong> </p>
        <p style="font-size: 0.8rem; margin: 0.5rem 0;"><strong>Table:</strong> ${roomData.table}/${roomData.room} <strong>   Bill No:</strong> </p>
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <div>
          <table style="width: 100%; font-size: 0.8rem; border-collapse: collapse;">
            <thead>
              <tr>
                <th style="text-align: left;">Item</th>
                
                <th style="text-align: right;">Qty.</th>
               
              </tr>
            </thead>
            <tbody>
             ${extraCartItems &&extraCartItems.map(item => `
                <tr>
                  <td style="padding: 0.25rem 0;">
                    <strong>${item.itemName ? item.itemName : item.name}${item.selectedVariant?.varientName ? " - " + item.selectedVariant?.varientName : Array.isArray(item.selectedVariants) ? " - " + item.selectedVariant[0] : ""}</strong>
                  </td>
                  <td style="text-align: right;">${item?.quantity ? item?.quantity : item?.qty}</td>
                </tr>
              `).join('')}
              ${redItems.map(item => `
                <tr>
                  <td style="padding: 0.25rem 0;"><strong> ${item.itemName ?item.itemName:item.name}${item.selectedVariant?.varientName ? " - "+item.selectedVariant?.varientName:Array.isArray(item.selectedVariants)? " - "+item.selectedVariant[0]:""} </strong></td>
                 
                  <td style="text-align: right;">${item?.quantity?item?.quantity:item?.qty}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </div>
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <p style="text-align: center; font-size: 0.7rem; margin: 0.5rem 0;">Thank you for visiting!</p>
      </div>
    `;
    const WinPrint = window.open('', '', 'width=800,height=600');
    WinPrint.document.write(KOT);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    // changeKOTStatus({billNo:billNo})
    return WinPrint.close();
  };