import React, { useEffect, useState } from "react";
import { printKOT } from "../Print/PrintKOT";
import { editExistingOrder, getPublicItemBYVPA } from "./Service/KotStatusChange.service";
import { getAllItems } from "../Menu/MenuService/Item.service";

const AddItemComponent = ({roodata,billNo,vpa}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState({});
  const [quantities, setQuantities] = useState({});
  const [customItemName, setCustomItemName] = useState("");
  const [customItemPrice, setCustomItemPrice] = useState("");
  const [customItemQuantity, setCustomItemQuantity] = useState(1); // Quantity for custom item
  const[foodItems, setFoodItems] = useState();

//   const foodItems = [
//     {
//       id: 13,
//       name: "Grilled Chicken Sandwich",
//       price: "8.99",
//       description: "A delicious grilled chicken sandwich with fresh lettuce, tomatoes, and our special sauce.",
//       category: "Test Category",
//       imageUrl: "",
//       halfPrice: "4.99",
//       variant: [
//         { id: 1, varientName: "Extra Cheese", varientAmount: "1" },
//         { id: 2, varientName: "Bacon", varientAmount: "1.5" },
//       ],
//     },
//     {
//       id: 14,
//       name: "Veggie Burger",
//       price: "7.99",
//       description: "A healthy veggie burger with organic ingredients.",
//       category: "Test Category",
//       imageUrl: "",
//       halfPrice: "4.99",
//       variant: [],
//     },
//   ];

  useEffect(()=>{
    const fetch = async()=>{
      const data=  await getPublicItemBYVPA({vpa});
    //   item
    console.log(data.data.data);
    
      setFoodItems(data.data.data)

    }
    fetch();
  },[])

  const filteredItems = foodItems && foodItems.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleItemClick = (item) => {
    setSelectedItems((prevItems) => [...prevItems, item]);
    setSelectedVariants((prev) => ({ ...prev, [item.id]: [] }));
    setQuantities((prev) => ({ ...prev, [item.id]: 1 })); // Default quantity to 1
    setSearchTerm("");
  };

  const handleVariantChange = (itemId, variantId) => {
    setSelectedVariants((prev) => {
      const currentVariants = prev[itemId] || [];
      return {
        ...prev,
        [itemId]: currentVariants.includes(variantId)
          ? currentVariants.filter((id) => id !== variantId)
          : [...currentVariants, variantId],
      };
    });
  };

  const handleQuantityChange = (itemId, delta) => {
    setQuantities((prev) => ({
      ...prev,
      [itemId]: Math.max((prev[itemId] || 1) + delta, 1), // Ensure quantity doesn't go below 1
    }));
  };

  const handleRemoveItem = (itemId) => {
    setSelectedItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
    setSelectedVariants((prev) => {
      const newVariants = { ...prev };
      delete newVariants[itemId];
      return newVariants;
    });
    setQuantities((prev) => {
      const newQuantities = { ...prev };
      delete newQuantities[itemId];
      return newQuantities;
    });
  };

  const getTotalPrice = (item) => {
    const basePrice = parseFloat(item.price);
    const variantCost = (item.variant || [])
      .filter((v) => selectedVariants[item.id]?.includes(v.id))
      .reduce((acc, curr) => acc + parseFloat(curr.varientAmount), 0);
    const quantity = quantities[item.id] || 1;
    return ((basePrice + variantCost) * quantity).toFixed(2);
  };

  const handleSaveItems = async() => {
    const savedItems = selectedItems.map((item) => {
      const selectedVariantIds = selectedVariants[item.id] || [];
      const selectedVariantNames = (item.variant || [])
        .filter((v) => selectedVariants[item.id]?.includes(v.id))
        .map((v) => v.varientName);

      return {
        itemId: item.id,
        quantity: quantities[item.id],
        varientId: selectedVariantIds.length > 0 ? selectedVariantIds[0] : null,
        itemName: item.name,
        basePrice: item.price,
        selectedVariants: selectedVariantNames,
        totalPrice: getTotalPrice(item),
      };
    });

    if (customItemName && customItemPrice) {
      savedItems.push({
        itemId: `custom`, // Custom item IDs will be unique
        quantity: customItemQuantity,  // Use custom item quantity
        varientId: null,
        itemName: customItemName,
        basePrice: customItemPrice,
        selectedVariants: [],
        totalPrice: (parseFloat(customItemPrice) * customItemQuantity).toFixed(2), // Calculate total price
      });
    }

    // Original format
    const originalFormat = savedItems.map((item) => {
        if(item.itemId=="custom"){
            return {
                itemId: item.itemId,
                quantity: item.quantity,
                varientIid: item.varientId,
                name: item.itemName,
                amount: item.basePrice
              } 

        }else{
            return {
                itemId: item.itemId,
                quantity: item.quantity,
                varientid: item.varientId,
              }
        }
    });

    // New format with basePrice, itemName, selectedVariants, totalPrice
    const newFormat = savedItems.map((item) => ({
      itemName: item.itemName,
      basePrice: item.basePrice,
      quantity: item.quantity,
      selectedVariants: item.selectedVariants,
      totalPrice: item.totalPrice,
    }));


    /// billNo
    const itemorderapi = await editExistingOrder({
        billNo,
        items:originalFormat
    })
    if(itemorderapi.data.event){

        printKOT("KOT CheckIn",newFormat,roodata);
        
    }else{
        alert("Not Order ")
    }

    
        console.log("Original Format:", { items: originalFormat });
        console.log("New Format:", newFormat);
  };

  const handleAddCustomItem = () => {
    if (customItemName && customItemPrice) {
      setSelectedItems((prev) => [
        ...prev,
        {
          id: Date.now(),
          name: customItemName,
          price: customItemPrice,
          variant: [],
        },
      ]);
      setCustomItemName("");
      setCustomItemPrice("");
      setCustomItemQuantity(1); // Reset quantity to 1 after adding
    }
  };

  return (
    <div className="mb-8">
      {/* Search input for items */}
      <input
        type="text"
        placeholder="Search for item"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="border p-2 rounded mr-2"
      />

      {/* Display matching items when user types */}
      {searchTerm && (
        <div className="border p-2 rounded mt-2 max-h-40 overflow-y-auto">
          {filteredItems?.length > 0 ? (
            filteredItems.map((item) => (
              <div
                key={item.id}
                className="cursor-pointer p-2 hover:bg-gray-100"
                onClick={() => handleItemClick(item)}
              >
                {item.name} - {item.price}
              </div>
            ))
          ) : (
            <div>No items found</div>
          )}
        </div>
      )}

      {/* Show selected items */}
      <div className="mt-4">
        {selectedItems.map((item) => (
          <div key={item.id} className="mb-4 border p-2 rounded flex justify-between">
           <div>
           <h3>{item.name}</h3>
            

            {/* Variant selection if available */}
            {item.variant?.length > 0 && (
              <div>
               
                {item.variant.map((v) => (
                  <div key={v.id}>
                    <input
                      type="checkbox"
                      id={`variant-${item.id}-${v.id}`}
                      value={v.id}
                      onChange={() => handleVariantChange(item.id, v.id)}
                    />
                    <label htmlFor={`variant-${item.id}-${v.id}`}>
                      {v.varientName} (+RS {v.varientAmount})
                    </label>
                  </div>
                ))}
              </div>
            )}
           </div>

           <div>

             {/* Quantity control */}
             <div className="flex items-center mt-2">
              <button
                className="bg-red-500 text-white px-2 py-1 rounded"
                onClick={() => handleQuantityChange(item.id, -1)}
              >
                -
              </button>
              <span className="mx-2">{quantities[item.id] || 1}</span>
              <button
                className="bg-green-500 text-white px-2 py-1 rounded"
                onClick={() => handleQuantityChange(item.id, 1)}
              >
                +
              </button>
            </div>

            <p>Total Price: Rs {getTotalPrice(item)}</p>
            <button
              className="bg-red-500 text-white px-3 py-1 rounded mt-2"
              onClick={() => handleRemoveItem(item.id)}
            >
              Remove Item
            </button>
           </div>
          </div>
        ))}
      </div>

      {/* Add custom item */}
      <div className="mt-4">
        <h4>Add Custom Item</h4>
        <input
          type="text"
          placeholder="Item Name"
          value={customItemName}
          onChange={(e) => setCustomItemName(e.target.value)}
          className="border p-2 rounded mr-2"
        />
        <input
          type="text"
          placeholder="Item Price"
          value={customItemPrice}
          onChange={(e) => setCustomItemPrice(e.target.value)}
          className="border p-2 rounded mr-2"
        />
        <input
          type="number"
          placeholder="Quantity"
          value={customItemQuantity}
          onChange={(e) => setCustomItemQuantity(parseInt(e.target.value, 10))}
          min="1"
          className="border p-2 rounded mr-2"
        />
        <button
          className="bg-green-500 text-white px-3 py-1 rounded"
          onClick={handleAddCustomItem}
        >
          Add Item
        </button>
      </div>

      {/* Save all items */}
      <button
        className="bg-blue-500 text-white px-3 py-1 rounded mt-4"
        onClick={handleSaveItems}
      >
        Save  & Print Kot
      </button>
    </div>
  );
};

export default AddItemComponent;



// export default AddItemComponent;
