import React, { useState } from 'react';
import Sidebar from './Sidebar';
import OrderHistory from './OrderHistory';
import MenuTabs from './Sidebar';


function OrderMain() {
    const [activeTab, setActiveTab] = useState('Table Service');
  return (
    <div className="justify-center">
    {/* <MenuTabs /> */}
    <div className="flex justify-center ">
      <OrderHistory activeTab={activeTab} setActiveTab={setActiveTab} />
    </div>
  </div>
  );
}

export default OrderMain;
