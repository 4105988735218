import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const CartItem = ({ item, onQuantityChange, onDeleteItem }) => {
  console.log(item, "cait");

  return (
    <li className="flex justify-between items-center mb-2">
      <div className="max-w-40 min-w-40">
        <h4>{item.name}</h4>
        {/* Display variant name if present */}
        {item.selectedVariant && (
          <p className="text-sm text-gray-500">
            {item.selectedVariant.varientName}
          </p>
        )}
      </div>
     
      <div className="flex items-center">
        <button
          className="bg-gray-300 px-2"
          onClick={() => 
            onQuantityChange(item.name, item.selectedVariant?.id, item.qty > 1 ? item.qty - 1 : 1)
          }
        >
          -
        </button>
        <span className="mx-2">{item.qty}</span>
        <button
          className="bg-gray-300 px-2"
          onClick={() => 
            onQuantityChange(item.name, item.selectedVariant?.id, item.qty + 1)
          }
        >
          +
        </button>
        {/* Calculate price based on selected variant or default price */}
        <h4 className="pl-6 min-w-5">
          ₹ {parseFloat(item.selectedVariant ? item.selectedVariant.varientAmount : item.price) * parseFloat(item.qty)}
        </h4>
        <button
          className="ml-4 text-red-500"
          onClick={() => 
            onDeleteItem(item.name, item.selectedVariant?.id)
          }
        >
          <FontAwesomeIcon icon={faTrash} className="mr-2" />
        </button>
      </div>
    </li>
  );
};

export default CartItem;
