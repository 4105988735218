import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.addEventListener('message', function(event) {
//     alert("Shubh")
//     window.location.reload();
//     console.log('Received message from service worker:', event.data.notification.title);
//     if (event.data.notification.title=="NOTIFICATION_RECEIVED") {
//     // location.reload();
     
//     }
//   });
// }


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
