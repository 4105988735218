import React, { useEffect, useState } from "react";

const Switchtable = ({ table,close }) => {
  const [alltable, setAllltable] = useState([]);

  useEffect(() => {
    if (table && table.length > 0) {
      // Gather all the data from table and update state once
      const allTableData = table
        .filter((ele) => ele.roomName !== "Parcel")
        .map((ele) => ele.tableData);
      setAllltable(allTableData.flat());
    }
  }, [table]); // UseEffect will run whenever `table` changes

  return (
    <div className="absolute top-3 left-1/2 transform -translate-x-1/2 bg-white shadow-lg rounded-lg p-6 w-96">
      <h3 className="text-lg font-bold mb-6 text-gray-700">
        Switch to a different table
      </h3>

      <div className="flex space-x-4">
        {/* First Table Select */}
        {/* <div className="w-1/2">
          <label htmlFor="table" className="block text-gray-600 mb-2">
           From
          </label>
          <select
            id="table"
            className="w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-blue-500 focus:outline-none transition duration-300 ease-in-out"
          >
            {alltable &&
              alltable.map((ele, index) => (
                <option key={index} value={ele.qrpidVPA}>
                  Table {ele.tableNo}
                </option>
              ))}
          </select>
        </div> */}
        Work In Progress 

        {/* Second Table Select */}
        {/* <div className="w-1/2">
          <label htmlFor="table2" className="block text-gray-600 mb-2">
            To
          </label>
          <select
            id="table2"
            className="w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-blue-500 focus:outline-none transition duration-300 ease-in-out"
          >
            {alltable &&
              alltable.map((ele, index) => (
                <option key={index} value={ele.qrpidVPA}>
                  Table {ele.tableNo}
                </option>
              ))}
          </select>
        </div> */}
      </div>

      {/* Buttons Section */}
      <div className="flex justify-end mt-6 space-x-3">
        <button onClick={close} className="bg-gray-400 text-white py-2 px-4 rounded-md hover:bg-gray-500 transition duration-300">
          Cancel
        </button>
        <button onClick={close} className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300">
          Confirm
        </button>
      </div>
    </div>
  );
};

export default Switchtable;
