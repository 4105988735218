import React, { useState } from 'react';

const SetACCharge = () => {
  const [acCharge, setAcCharge] = useState('');

  const handleAcChargeChange = (e) => {
    setAcCharge(e.target.value);
  };

  const handleSave = () => {
    // Implement logic to save AC charge to the database or application state
    // console.log(`AC Charge set to: ${acCharge}`);
    alert('AC Charge saved successfully!');
  };

  return (
    <div className="mb-4">
      <h2 className="text-lg font-bold">Set AC Charge</h2>
      <input
        type="number"
        value={acCharge}
        onChange={handleAcChargeChange}
        placeholder="Enter AC Charge"
        className="border p-2 w-full mb-2"
      />
      <button
        onClick={handleSave}
        className="bg-blue-500 text-white px-4 py-2 rounded"
      >
        Save
      </button>
    </div>
  );
};

export default SetACCharge;
