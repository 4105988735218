import React, { useState } from "react";
import { cancleOrder } from "../../../Orders/orderService/orderService";
import { useNavigate } from "react-router-dom";

const OrderSummary = ({ cartItem, total, itemCount, bookOrder, cancleAndUpdateKOT, billNo,saveOnly }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to control popup visibility
  const [reason, setReason] = useState(''); // State for reason input
  const [password, setPassword] = useState(''); // State for password input
  const [error , setError] = useState();
  const navigate = useNavigate();
  const [disbalesave, setDisableSavebutton]= useState(false);

  const obj = {
    "browsertoken": "shu",
    "name": "shubham",
    "mobileNo": "6884242",
    "items": [
      {
        "itemId": 13,
        "quantity": 2,
        "varientid": 2
      }
    ]
  };
  console.log(cartItem, "....", obj);

  const handleCancelOrder = () => {
    setIsPopupOpen(true); // Open the popup when "Cancel Order" is clicked
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false); // Close the popup
  };

  const handleSubmit = async() => {
    if (reason && password && billNo) {
      // Process cancellation here
      const output = await cancleOrder({
        billNo:billNo, password:password, reason:reason
      })
      console.log('Reason:', reason, 'Password:', password);
      // Close popup after processing
      if(output.data.event){

        setIsPopupOpen(false);
       window.location.reload()
      }else{
        setError("Password Wrong.")
      }
    } else {
      setError("Please provide both reason and password.")
      // alert("Please provide both reason and password.");
    }
  };

  return (
    <div className="mt-6">
      <div className="flex justify-between mb-2">
        <span>Total Items:</span>
        <span>{itemCount}</span>
      </div>
      <div className="flex justify-between font-semibold text-lg">
        <span>Total:</span>
        {/* <span>₹{total }</span> */}
      </div>

      <div className="mt-6 bottom-0 flex justify-between">
        {billNo && <button className="bg-blue-500 text-white py-2 px-4 rounded" onClick={cancleAndUpdateKOT}>
          Print Update KOT
        </button>}
        {!billNo && <button onClick={bookOrder} className="bg-blue-500 text-white py-2 px-4 rounded">
          Save & Print KOT
        </button>}
        <button disabled={disbalesave}  onClick ={()=>{setDisableSavebutton(true);
          saveOnly()
        }} className="bg-blue-500 text-white py-2 px-4 rounded">Save </button>
        {billNo && <button className="bg-red-300 text-white py-2 px-4 rounded" onClick={handleCancelOrder}>
          Cancel Order
        </button>}
      </div>

      {/* Popup for cancellation reason and password */}
      {isPopupOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-lg font-semibold mb-4">Cancel Order</h2>
            
            <div className="mb-4">
              <label className="block mb-2">Password:</label>
              <input
                type="password"
                className="border p-2 w-full"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Reason for cancellation:</label>
              <input
                type="text"
                className="border p-2 w-full"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </div>
            {error &&<p className="text-red-300">{error}</p>}
            <div className="flex justify-end space-x-4">
              <button className="bg-gray-500 text-white py-2 px-4 rounded" onClick={handleClosePopup}>
                Close
              </button>
              <button className="bg-red-500 text-white py-2 px-4 rounded" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderSummary;
