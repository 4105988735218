import React from 'react';

const TopSellingItems = ({ name, list }) => {
  return (
    <div className="bg-white shadow rounded p-4">
      <h2 className="text-lg font-semibold mb-4">today {name} payment</h2>
      <table className="w-full border-collapse">
        <thead>
          <tr>
            <th className="border-b-2 border-gray-300 py-2 text-left">Bill No</th>
            <th className="border-b-2 border-gray-300 py-2 text-right">Amount</th>
          </tr>
        </thead>
        <tbody>
          {list
            ?.filter((ele) => ele.name === name)
            .sort((a, b) => b.billNo.localeCompare(a.billNo)) // Sort by billNo from big to small
            .map((item, index) => (
              <tr key={index} className="border-b hover:bg-gray-100">
                <td className="py-2">{item.billNo}</td>
                <td className="py-2 text-right">{item.amount}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopSellingItems;
