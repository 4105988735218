import React from 'react'
import SetACCharge from './subsetting/SetACCharge'
import SpecialOffer from './subsetting/SpecialOffer'
import SendOfferNotification from './subsetting/SendOfferNotification'
import SetGST from './subsetting/SetGST'

const Setting = () => {
  return (
    <div className="p-8 blur-sm">
    <SetACCharge />
    <SpecialOffer />
    <SendOfferNotification />
    <SetGST />
    {/* <MoreSettings /> */}
  </div>
  )
}

export default Setting