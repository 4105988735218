import { api } from "../../Environments/Environment.Test";

const authUrl = `${api}/restaurant`;
export function addCategoryAPI(requestBody) {
  // const navigate = useNavigate();

  return new Promise(async (resolve) => {
    try {
      const token = await localStorage.getItem("authToken");
      const response = await fetch(`${authUrl}/admin/category`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      resolve({ error: error.message });
    }
  });
}

export function allCatrgories() {
  // const navigate = useNavigate();

  return new Promise(async (resolve) => {
    try {
      const token = await localStorage.getItem("authToken");
      const response = await fetch(`${authUrl}/admin/category`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        //   body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      resolve({ error: error.message });
    }
  });
}

export function deletItem(id) {
  // const navigate = useNavigate();

  return new Promise(async (resolve) => {
    try {
      const token = await localStorage.getItem("authToken");
      const response = await fetch(`${authUrl}/admin/deleteMenu?id=${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        //   body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      resolve({ error: error.message });
    }
  });
}
