import { api } from "../../Environments/Environment.Test";

const authUrl = `${api}/anylisis`

export function daywise() {
    // const navigate = useNavigate();
  
    return new Promise(async (resolve) => {
      try {
        const token = await localStorage.getItem("authToken");
        const response = await fetch(`${authUrl}/payments/day-wise`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        //   body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        resolve({ data });
      } catch (error) {
        resolve({ error: error.message });
      }
    });
  }

  export function monthwise() {
    // const navigate = useNavigate();
  
    return new Promise(async (resolve) => {
      try {
        const token = await localStorage.getItem("authToken");
        const response = await fetch(`${authUrl}/payments/month-wise`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        //   body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        resolve({ data });
      } catch (error) {
        resolve({ error: error.message });
      }
    });
  }

  export function yearwise() {
    // const navigate = useNavigate();
  
    return new Promise(async (resolve) => {
      try {
        const token = await localStorage.getItem("authToken");
        const response = await fetch(`${authUrl}/payments/lifetime`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        //   body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        resolve({ data });
      } catch (error) {
        resolve({ error: error.message });
      }
    });
  }

  // /order/history/customdate/wise

  export function customdatewise(requestBody) {
    // const navigate = useNavigate();
  
    return new Promise(async (resolve) => {
      try {
        const token = await localStorage.getItem("authToken");
        const response = await fetch(`${authUrl}/order/history/customdate/wise`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        resolve({ data });
      } catch (error) {
        resolve({ error: error.message });
      }
    });
  }

//   /orders/report


export function reportallYear() {
    // const navigate = useNavigate();
  
    return new Promise(async (resolve) => {
      try {
        const token = await localStorage.getItem("authToken");
        const response = await fetch(`${authUrl}/orders/report`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        //   body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        resolve({ data });
      } catch (error) {
        resolve({ error: error.message });
      }
    });
  }