import React, { useEffect, useState } from "react";
import Header1 from "./Header/Header2";
import AcceptOrder from "./AcceptOrder/AcceptOrder.main";
import {
  GetTableData,
  RequestedKOTData,
  TableData,
} from "./services/table.Services";
import { FaEye, FaPrint } from "react-icons/fa6";
import { requestFcmToken } from "../utils/firebaseUtils";
import { useNavigate } from "react-router-dom";
import { playRing } from "./PlaySound";
import Switchtable from "./Switchtable";
// import io from 'socket.io-client'; // Import socket.io-client
// import { api } from "./Environments/Environment.Test";

// const socket = io(api);\

navigator.serviceWorker.addEventListener("message", function (event) {
  // alert("Shubh")
  console.log(
    "Received message from service worker:",
    event.data.notification.title
  );
  if (event.data.notification.title == "NOTIFICATION_RECEIVED") {
    playRing();
    window.location.reload();
    // location.reload();
  }
});

const TableOverview = () => {
  const [activeRoom, setActiveRoom] = useState("Room-2");
  const [tables, setTables] = useState([]);
  const [showPrompt, setShowPrompt] = useState(false);
  const [clickedTable, setClickedTable] = useState(null);
  const [tableStatuses, setTableStatuses] = useState({});
  const [resData, setResData] = useState();
  const [tablesroom, setTablesRoom] = useState();
  const [ordertables, setOrdersTable] = useState([]);
  const [printedKOT, setPrintedKOT] = useState([]);
  const [printedKOTAll, setPrintedKOTAll] = useState([]);
  const [address, setAddress] = useState();
  const [selectedPrintedKOTAll, setselectedPrintedKOTAll] = useState([]);
  const [rander, setForceRender] = useState();
  const [requestKOTAll, setrequestKOTAll] = useState([]);
  const [selectedrequestKOTAll, setselectedrequestKOTAll] = useState([]);

  const handleOpenPrompt = async (id, qrpidVPA) => {
    setShowPrompt(true);

    // console.log(qrpidVPA,"...............");

    setClickedTable({ id: id, qrpidVPA: qrpidVPA });
    const totalPr = [];
    await printedKOTAll?.map((ele) => {
      if (ele.tableVPA == qrpidVPA) {
        totalPr.push(ele);

        setselectedPrintedKOTAll(totalPr);
      }
    });
    // console.log(totalPr,".........");

    const kot = [];
    await requestKOTAll?.map((ele) => {
      if (ele.tableVPA == qrpidVPA) {
        kot.push(ele);
        setselectedrequestKOTAll(kot);
      }
    });
    // console.log(id);
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
    window.location.reload();
  };
  // socket.on('dataUpdated', () => {
  //   alert('Data has been updated, reloading page...');
  //   window.location.reload(); // Reload the page when data is updated
  // });

  useEffect(() => {
    const fetchTables = async () => {
      try {
        const token = await localStorage.getItem("authToken");

        // Fetch and set table data
        const { data } = await TableData(token);
        setResData(data.data);
        setActiveRoom(data.data[0].roomName);
        setTablesRoom(data.data[0].tableData);

        // Set interval for request KOT data
        const requestKOT = await RequestedKOTData(token);
        setrequestKOTAll(requestKOT.data.requestKOTAmoutntItem);

        const setRequestKOT = [];
        requestKOT.data.requestKot.forEach((ele) => {
          setRequestKOT.push(ele.tableVPA);
        });
        setOrdersTable(setRequestKOT);

        // const inte = setInterval(async () => {
        //   try {
        //     const requestKOT = await RequestedKOTData(token);
        // setrequestKOTAll(requestKOT.data.requestKOTAmoutntItem);

        // const setRequestKOT = [];
        // requestKOT.data.requestKot.forEach((ele) => {
        //   setRequestKOT.push(ele.tableVPA);
        // });

        // setOrdersTable(setRequestKOT);
        //   } catch (error) {
        //     console.error("Failed to fetch KOT data", error);
        //   }
        // }, 10000); // 500000 ms = 500 seconds

        // Cleanup function to clear interval on component unmount

        // Fetch and set printed KOT data
        const datas = await GetTableData(token);
        setPrintedKOTAll(datas.data.kotPrintedItem);

        const printedKOT = [];
        datas.data.vpaKotPrinted.forEach((ele) => {
          printedKOT.push(ele.tableVPA);
        });
        setPrintedKOT(printedKOT);

        // Set address
        setAddress({
          name: localStorage.getItem("name"),
          address: localStorage.getItem("address"),
        });
        // return () => clearInterval(inte);
        //   socket.on('data-update', async(data) => {
        //     // Handle data update
        //     console.log(data.email);
        //     const email = localStorage.getItem("email");
        //     console.log(email);

        //     if(data.email==email ){
        //       console.log(data);

        //       // alert("New Order Please Refresh Page ");
        //       console.log(data.tablevpa,"...................");

        //       const item=requestKOTAll;
        //       item.push(data.tablevpa)

        //       setrequestKOTAll(item)
        //       setForceRender(prev => !prev);
        //     }

        //     // updateStateWithNewData(data);
        // });

        // setInterval(()=>{
        //   setForceRender(prev => !prev);

        // },10000)

        // return () => {
        //     socket.disconnect();
        // };
        const fetch = async () => {
          const key = await requestFcmToken();
          console.log(key, "...............");
        };
        fetch();
      } catch (error) {
        console.error("Failed to fetch tables", error);
      }
    };

    fetchTables();
  }, [rander]); // Empty dependency array means this runs only once on mount

  const handleRoomChange = (room) => {
    setActiveRoom(room.roomName);
    setTablesRoom(room.tableData);

    setTableStatuses({});
  };

  const handleKOTGenerate = () => {
    if (clickedTable) {
      setTableStatuses((prevStatuses) => ({
        ...prevStatuses,
        [clickedTable]: "printed",
      }));
    }
  };

  const handleBillGenerate = () => {
    if (clickedTable) {
      setTableStatuses((prevStatuses) => ({
        ...prevStatuses,
        [clickedTable]: "blank",
      }));
    }
  };
  const [activeTab, setActiveTab] = useState("dine-in");
  const navigate = useNavigate();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  return (
    <>
      <Header1 />
      <div className="flex border-b border-gray-300 mb-4">
        <button
          className={`py-2 px-4 text-lg font-semibold ${
            activeTab === "dine-in"
              ? "border-b-2 border-yellow-500 text-gray-800"
              : "text-gray-600"
          }`}
          onClick={() => {
            setActiveTab("dine-in");
            navigate("/table-overview");
          }}
        >
          Dine In
        </button>
        <button
          className={`py-2 px-4 text-lg font-semibold ${
            activeTab === "parcel"
              ? "border-b-2 border-yellow-500 text-gray-800"
              : "text-gray-600"
          }`}
          onClick={() => {
            setActiveTab("parcel");
            navigate("/order-parcel");
          }}
        >
          Parcel
        </button>
      </div>
      {!showPrompt && (
        <div className="bg-white shadow-lg rounded-lg p-6 mt-6">
          {/* Color Legend at the top */}
          <div className="mb-4  flex justify-between">
            <div className="space-x-4">
              <span className="text-gray-500">Legend:</span>
              <span className="inline-flex items-center">
                <span className="w-4 h-4 bg-gray-300 inline-block mr-1 rounded"></span>{" "}
                Blank Table
              </span>
              <span className="inline-flex items-center">
                <span className="w-4 h-4 bg-blue-500 inline-block mr-1 rounded"></span>{" "}
                Request KOT
              </span>
              <span className="inline-flex items-center">
                <span className="w-4 h-4 bg-green-500 inline-block mr-1 rounded"></span>{" "}
                Printed Table
              </span>
            </div>
            <div>
              <button className="py-2 px-4 text-lg font-semibold rounded-md bg-gray-800 text-white" onClick={ togglePopover}>Switch Table</button>
            </div>
          </div>
          {isPopoverOpen && <Switchtable close={()=>{togglePopover()}} table={resData} />}

          <div className="flex justify-centre	">
            {/* Room Buttons Section */}
            {/* <div className="w-1/4 pr-4 mr-20">
              <div className="bg-gray-100 p-4 rounded-lg shadow-md">
                <h3 className="text-lg font-bold mb-4">Rooms</h3>
                {resData &&
                  resData.map((ele) => (
                    <button
                      onClick={() => handleRoomChange(ele)}
                      className={`w-full py-2 mb-2 rounded-md text-sm font-medium ${
                        activeRoom === ele.roomName
                          ? "bg-gray-800 text-white"
                          : "bg-gray-200 text-gray-700"
                      }`}
                    >
                      {ele.roomName}
                    </button>
                  ))}
              </div>
            </div> */}
            {/* {console.log(resData.length, "................")} */}
            <div className="w-full">
                {resData &&
                  resData?.map((tablesroom,index) => {
                    return (
                      <>
                   
                        <div className="relative flex items-center justify-center my-6">
                          {/* Dotted Line */}
                          <div className="border-t border-gray-400 border-dotted w-8"></div>
                          <span className=" bg-white min-w-[100px] max-w-[300px]  px-4 text-gray-600 font-bold">
                            {tablesroom?.roomName}
                          </span>
                          <div className="border-t border-gray-400 border-dotted w-full"></div>

                          {/* Parcel Label */}
                        </div>
                      <div className="grid grid-cols-10 gap-4">

                        {tablesroom?.tableData?.map((table) => (
                          <div
                            //  oncl/
                            onClick={() =>
                              handleOpenPrompt(table.tableNo, table.qrpidVPA)
                            }
                            key={table.tableNo}
                            className={`rounded-lg h-20 w-20 text-center cursor-pointer text-lg font-bold w-full aspect-w-1 aspect-h-1 flex flex-col items-center justify-center ${
                              ordertables &&
                              ordertables.includes(table.qrpidVPA)
                                ? "bg-blue-500 text-white animate-flash"
                                : printedKOT &&
                                  printedKOT.includes(table.qrpidVPA)
                                ? "bg-green-500 text-white"
                                : "bg-gray-300 text-gray-900 "
                            }`}
                          >
                            <div>{(index+1)==resData.length?"P":"T"} {table.tableNo}</div>
                            {printedKOT.includes(table.qrpidVPA) && (
                              <div className="text-sm font-normal">
                                {console.log(
                                  table,
                                  "..table ..",
                                  printedKOTAll
                                )}
                                <strong>
                                  {" "}
                                  ₹{" "}
                                  {
                                    printedKOTAll.find(
                                      (item) => item.tableVPA === table.qrpidVPA
                                    )?.amount
                                  }
                                </strong>
                                <div className="flex justify-center mt-1 ">
                                  <FaEye
                                    // onClick={() => handleOpenPrompt(table.tableNo)}
                                    className="text-lg cursor-pointer"
                                    // Add your view handler here
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                        </div>
                        <br />
                      </>
                    );
                  })}
            </div>

            {/* <div className="w-3/4">
              <div className="grid grid-cols-5 gap-4">
                {tablesroom &&
                  tablesroom.slice(0, -5).map((table) => (
                    <div
                      
                      onClick={() =>
                        handleOpenPrompt(table.tableNo, table.qrpidVPA)
                      }
                      key={table.tableNo}
                      className={`rounded-lg h-20 w-20 text-center cursor-pointer text-lg font-bold w-full aspect-w-1 aspect-h-1 flex flex-col items-center justify-center ${
                        ordertables && ordertables.includes(table.qrpidVPA)
                          ? "bg-blue-500 text-white"
                          : printedKOT && printedKOT.includes(table.qrpidVPA)
                          ? "bg-green-500 text-white"
                          : "bg-gray-300 text-gray-900"
                      }`}
                    >
                      <div>T {table.tableNo}</div>
                      {printedKOT.includes(table.qrpidVPA) && (
                        <div className="text-sm font-normal">
                          {console.log(table, "..table ..", printedKOTAll)}
                          <strong>
                            {" "}
                            ₹{" "}
                            {
                              printedKOTAll.find(
                                (item) => item.tableVPA === table.qrpidVPA
                              )?.amount
                            }
                          </strong>
                          <div className="flex justify-center mt-1 ">
                            <FaEye
                            
                              className="text-lg cursor-pointer"
                           
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                <br />
              </div>
              <br />
              <br />
              <br />
              <div className="relative flex items-center justify-center my-6">
               
                <div className="border-t border-gray-400 border-dotted w-full"></div>

                
                <span className="absolute bg-white px-4 text-gray-600 font-bold">
                  Parcel
                </span>
              </div>

              <div className="grid grid-cols-5 gap-4">
                {tablesroom &&
                  tablesroom.slice(-5).map((table, index) => (
                    <div
                      //  oncl/
                      onClick={() =>
                        handleOpenPrompt(table.tableNo, table.qrpidVPA)
                      }
                      key={table.tableNo}
                      className={`rounded-lg h-20 w-20 text-center cursor-pointer text-lg font-bold w-full aspect-w-1 aspect-h-1 flex flex-col items-center justify-center ${
                        ordertables && ordertables.includes(table.qrpidVPA)
                          ? "bg-blue-500 text-white"
                          : printedKOT && printedKOT.includes(table.qrpidVPA)
                          ? "bg-green-500 text-white"
                          : "bg-gray-300 text-gray-900"
                      }`}
                    >
                      <div>P {index + 1}</div>
                      {printedKOT.includes(table.qrpidVPA) && (
                        <div className="text-sm font-normal">
                          {console.log(table, "..table ..", printedKOTAll)}
                          <strong>
                            {" "}
                            ₹{" "}
                            {
                              printedKOTAll.find(
                                (item) => item.tableVPA === table.qrpidVPA
                              )?.amount
                            }
                          </strong>
                          <div className="flex justify-center mt-1 ">
                            <FaEye
                              // onClick={() => handleOpenPrompt(table.tableNo)}
                              className="text-lg cursor-pointer"
                              // Add your view handler here
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                <br />
              </div>
            </div> */}
            {/* <div className="w-3/4">
              <div className="grid grid-cols-5 gap-4">
                {tablesroom &&
                  tablesroom.map((table) => (
                    <div
                      onClick={() => handleOpenPrompt(table.tableNo)}
                      key={table.tableNo}
                      className={`rounded-lg h-20 w-20 text-center cursor-pointer text-lg font-bold w-full aspect-w-1 aspect-h-1 flex flex-col items-center justify-center ${
                        ordertables.includes(table.qrpidVPA)
                          ? "bg-blue-500 text-white"
                          : printedKOT.includes(table.qrpidVPA)
                          ? "bg-green-500 text-white"
                          : "bg-gray-300 text-gray-900"
                      }`}
                    >
                      T {table.tableNo}
                      {printedKOT.includes(table.qrpidVPA) && (
                        <span className="text-sm font-normal">
                          Amount: ₹{table.amount}{" "}
                          
                        </span>
                      )}
                    </div>
                  ))}
              </div>
            </div> */}
          </div>
        </div>
      )}
      {/* {console.log(clickedTable)
      } */}
      {showPrompt && (
        <AcceptOrder
          handleClosePrompt={handleClosePrompt}
          data={{
            table: clickedTable.id,
            tableVPA: clickedTable.qrpidVPA,
            room: activeRoom,
          }}
          printed={selectedPrintedKOTAll}
          // kot={selectedrequestKOTAll}
          address={address}
          onKOTGenerate={handleKOTGenerate}
          onBillGenerate={handleBillGenerate}
        />
      )}
    </>
  );
};

export default TableOverview;

// import React, { useEffect, useState } from 'react';
// import Header1 from './Header/Header2';
// import AcceptOrder from './AcceptOrder/AcceptOrder.main';

// const TableOverview = () => {
//   const [activeRoom, setActiveRoom] = useState('Room-2');
//   const [tables, setTables] = useState([]);
//   const [showPrompt, setShowPrompt] = useState(false);
//   const [clickedTable, setClickedTable] = useState(null);
//   const [tableStatuses, setTableStatuses] = useState({});

//   const handleOpenPrompt = (id) => {
//     setShowPrompt(true);
//     setClickedTable(id);
//   };

//   const handleClosePrompt = () => {
//     setShowPrompt(false);
//   };

//   useEffect(() => {
//     const fetchTables = async () => {
//       try {
//         const tables = await new Promise((resolve) => {
//           setTimeout(() => {
//             resolve(
//               Array.from({ length: 15 }, (_, i) => ({
//                 id: `T${i + 1}`,
//                 status: 'blank', // Initial status
//               }))
//             );
//           }, 500);
//         });

//         setTables(tables);
//       } catch (error) {
//         console.error('Failed to fetch tables', error);
//       }
//     };

//     fetchTables();
//   }, [activeRoom]);

//   const handleRoomChange = (room) => {
//     setActiveRoom(room);
//     setTableStatuses({});
//   };

//   const handleKOTGenerate = () => {
//     if (clickedTable) {
//       setTableStatuses((prevStatuses) => ({
//         ...prevStatuses,
//         [clickedTable]: 'printed',
//       }));
//     }
//   };

//   const handleBillGenerate = () => {
//     if (clickedTable) {
//       setTableStatuses((prevStatuses) => ({
//         ...prevStatuses,
//         [clickedTable]: 'blank',
//       }));
//     }
//   };

//   return (
//     <>
//       <Header1 />
//       {!showPrompt && (
//         <div className="bg-white shadow-lg rounded-lg p-6 mt-6 flex justify-between">
//           {/* Room and Table Sections */}
//           <div className="flex w-3/4">
//             {/* Room Buttons Section */}
//             <div className="w-1/4 mr-6">
//               <div className="bg-gray-100 p-4 rounded-lg shadow-md">
//                 <h3 className="text-lg font-bold mb-4">Rooms</h3>
//                 <button
//                   onClick={() => handleRoomChange('AC Premium')}
//                   className={`w-full py-2 mb-2 rounded-md text-sm font-medium ${
//                     activeRoom === 'AC Premium' ? 'bg-gray-800 text-white' : 'bg-gray-200 text-gray-700'
//                   }`}
//                 >
//                   AC Premium
//                 </button>
//                 <button
//                   onClick={() => handleRoomChange('Room-2')}
//                   className={`w-full py-2 mb-2 rounded-md text-sm font-medium ${
//                     activeRoom === 'Room-2' ? 'bg-gray-800 text-white' : 'bg-gray-200 text-gray-700'
//                   }`}
//                 >
//                   Room-2
//                 </button>
//                 <button
//                   onClick={() => handleRoomChange('Room-3')}
//                   className={`w-full py-2 mb-2 rounded-md text-sm font-medium ${
//                     activeRoom === 'Room-3' ? 'bg-gray-800 text-white' : 'bg-gray-200 text-gray-700'
//                   }`}
//                 >
//                   Room-3
//                 </button>
//               </div>
//             </div>

//             {/* Table Buttons Section */}
//             <div className="w-3/4">
//               <div className="grid grid-cols-5 gap-4">
//                 {tables.map((table) => (
//                   <div
//                     onClick={() => handleOpenPrompt(table.id)}
//                     key={table.id}
//                     className={`rounded-lg h-20 w-20 text-center cursor-pointer text-lg font-bold w-full aspect-w-1 aspect-h-1 flex items-center justify-center ${
//                       tableStatuses[table.id] === 'printed'
//                         ? 'bg-green-500 text-white'
//                         : tableStatuses[table.id] === 'running'
//                         ? 'bg-blue-500 text-white'
//                         : table.status === 'running'
//                         ? 'bg-blue-500 text-white'
//                         : 'bg-gray-300 text-gray-900'
//                     }`}
//                   >
//                     {table.id}
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>

//           {/* Color Legend Section */}
//           <div className="w-1/4 pl-4">
//             <div className="bg-gray-100 p-4 rounded-lg shadow-md">
//               <h3 className="text-lg font-bold mb-4">Legend</h3>
//               <div className="space-y-2">
//                 <div className="flex items-center">
//                   <span className="w-4 h-4 bg-gray-300 inline-block mr-2 rounded"></span> Blank Table
//                 </div>
//                 <div className="flex items-center">
//                   <span className="w-4 h-4 bg-blue-500 inline-block mr-2 rounded"></span> Request KOT
//                 </div>
//                 <div className="flex items-center">
//                   <span className="w-4 h-4 bg-green-500 inline-block mr-2 rounded"></span> Printed Table
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//       {showPrompt && (
//         <AcceptOrder
//           handleClosePrompt={handleClosePrompt}
//           data={{ table: clickedTable, room: activeRoom }}
//           onKOTGenerate={handleKOTGenerate}
//           onBillGenerate={handleBillGenerate}
//         />
//       )}
//     </>
//   );
// };

// export default TableOverview;
