import { api } from "../../../../Environments/Environment.Test";



const authUrl = `${api}/restaurant`;
export function cancleItemKOT(requestBody) {
    // const navigate = useNavigate();
  
    return new Promise(async (resolve) => {
      try {
        const token = await localStorage.getItem("authToken");
        const response = await fetch(`${authUrl}/order/cancle/kot/password`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        resolve({ data });
      } catch (error) {
        resolve({ error: error.message });
      }
    });
  }

  // cancle order by item

  export function cancleExtraItemKOT(requestBody) {
    // const navigate = useNavigate();
  
    return new Promise(async (resolve) => {
      try {
        const token = await localStorage.getItem("authToken");
        const response = await fetch(`${authUrl}/order/cancle/extraItemKot/password`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        resolve({ data });
      } catch (error) {
        resolve({ error: error.message });
      }
    });
  }
//   /waiter/otherdata/get/generatebill

export function addExtraAmount(requestBody) {
  // const navigate = useNavigate();

  return new Promise(async (resolve) => {
    try {
      const token = await localStorage.getItem("authToken");
      const response = await fetch(`${authUrl}/order/addExtracharge`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      resolve({ error: error.message });
    }
  });
}


 